import { Image } from "antd";
import React from "react";
import styles from "./ServicePreview.module.scss";
import { BsGlobe } from "react-icons/bs";
import { MdOutlinePhoneAndroid, MdLocationOn } from "react-icons/md";
import { useSelector } from "react-redux";
import en from "../../localization/en";
import ar from "../../localization/ar";

function ServicePreview({ data, hideInfo }) {
  const { currentLang } = useSelector((state) => state.user);

  return (
    <div className={styles.service_preview_wrapper}>
      <div
        className={styles.img_holder}
        style={{
          backgroundImage: `linear-gradient(
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0.05)
            ),url(${data?.icon_image})`,
        }}
      >
        {/* <Image src={img} preview={false} alt="service image" /> */}
      </div>
      <div className={styles.text_holder}>
        <div className={styles.content}>
          <div className={styles.name}>
          {currentLang === "en" ? data?.title_en : data?.title_ar}
            
          </div>
        {!hideInfo &&
          <div className={styles.disc}>
            {/* <span>5 stars hotel</span>
            <span>located in : Lorem ipsum dolor sit amet consectetur.</span> */}
            <span className={styles.about}>
            {currentLang === "en" ? data?.description_en : data?.description_ar}
            </span>
          </div>
        }
        </div>
        <div className={styles.footer}>
          <ul className={styles.list}>
            {data?.phone &&
            <li>
              <a href={`tel:+${data?.phone}`}>
                <MdOutlinePhoneAndroid />
              </a>
            </li>
            }
            {data?.website &&
            <li>
              <a href={`${data?.website}`} target="_blank" rel="noopener noreferrer"><BsGlobe /></a>
            </li>
            }
            {data?.location &&
            <li>
              <a target="_blank" href={`${data?.location}`}>
                <MdLocationOn />
              </a>
              {/* <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${data?.address_latitude},${data?.address_longitude}`}>
                <MdLocationOn />
              </a> */}
            </li>
            }
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ServicePreview;
