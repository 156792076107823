import { Col, Pagination, Row } from "antd";
import SingleVideo from "../SingleVideo/SingleVideo";
import styles from "./MoreVideos.module.scss";
import en from "../../localization/en";
import ar from "../../localization/ar";
import { useSelector } from "react-redux";

import { useEffect, useState } from "react";

function MoreVideos({ list, id }) {
  const { currentLang } = useSelector((state) => state.user);
  const t = currentLang === "en" ? en : ar;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setpageSize] = useState(3);

  const onChange = (page) => {
    console.log(page);
    setCurrentPage(page);
  };
  const moreTitle = {
    5: t.MoreNews,
    7: t.MoreArticls,
    events: t.MoreEvents,
    world_information: t.MoreTopics,
  };
  return (
    <div className={styles.more_videos_wrapper}>
      <div className={styles.more_videos_holder}>
        <h2 className={styles.title_hero}>{moreTitle[id] || t.MoreVideos}</h2>
        <div className={styles.videos_holder}>
          {/* <div className={styles.videos_grid}>
          </div> */}
          <Row gutter={[25, 85]}>
            {list?.map((video) => (
              <Col key={video?.id} sm={16} md={10} xl={6}>
                <SingleVideo video={video} />
              </Col>
            ))}
          </Row>
          {/* <Row gutter={[25, 65]}>
            {list?.slice( ((currentPage - 1) * pageSize  + 1), currentPage * pageSize)?.map(video => 
            <Col key={video?.id} sm={24} md={12} xl={8}>
              <SingleVideo video={video} />
            </Col>
            )}
          </Row> */}
          {/* <div className={styles.pagination_holder}>
            <Pagination current={currentPage} hideOnSinglePage onChange={onChange} total={list?.length} pageSize={pageSize} />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default MoreVideos;
