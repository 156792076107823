import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { useLocalization } from "../../hooks/useLocalization";
import { Helmet } from "react-helmet";
import { Row, Col, Image } from "antd";
import NewsSection from "../../public/components/NewsSection/NewsSection";

import Footer from "../../public/components/Footer/Footer";

import styles from "./NewsPage.scss";
import TimeDisplay from "../../public/components/TimeDisplay/TimeDisplay";

export default function NewsPage(props) {
  const params = useParams();
  const [allNews, setAllNews] = useState([]);
  const { t, currentLang } = useLocalization();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/news`)
      .then((response) => response.json())
      .then((data) => {
        props?.type === "world"
          ? setAllNews(data?.data?.world_news || [])
          : setAllNews(data?.data?.china_news || []);
      });

    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, [params?.id]);

  const news = allNews?.find((news) => news?.id === parseInt(params?.id));
  const newsWithoutCurrent = allNews?.filter(
    (news) => news?.id !== parseInt(params?.id)
  );
  return (
    <>
      <div className="news_page">
        <Helmet>
          <title>
            {currentLang === "en" ? news?.title_en : news?.title_ar}
          </title>
          <meta
            name="title"
            content={currentLang === "en" ? news?.title_en : news?.title_ar}
          />
          <meta
            name="description"
            content={
              currentLang === "en" ? news?.description_en : news?.description_ar
            }
          />
          <meta
            property="og:title"
            content={currentLang === "en" ? news?.title_en : news?.title_ar}
          />
          <meta
            property="og:description"
            content={
              currentLang === "en" ? news?.description_en : news?.description_ar
            }
          />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
        <div className="container">
          <div className={"hero_content container"}>
            <Row gutter={[25, 25]} justify="space-between">
              <Col xs={24}>
                <div className={"cover_holder"} data-aos="fade-up">
                  <div className="media-img-holder">
                    <img src={news?.image} alt="news image" />
                  </div>
                </div>
              </Col>
              <Col xs={24}>
                <div className={"text_holder"} data-aos="fade-down">
                  <div className={"text_content"}>
                    <h4>
                      {currentLang === "en" ? news?.title_en : news?.title_ar}
                    </h4>
                    <p>
                      {currentLang === "en"
                        ? news?.description_en
                        : news?.description_ar}
                    </p>
                  </div>
                    <TimeDisplay date={news?.created_at} />
                </div>
              </Col>
            </Row>
          </div>

          <div className="slider_holder" data-aos="zoom-in">
            <NewsSection
              news={newsWithoutCurrent}
              title={
                props?.type === "world" ? t.moreWorldNews : t.moreChinaNews
              }
              type={props?.type}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
