import { useRef, useState } from "react";
import { Image } from "antd";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom";
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
import SliderMediaWrapper from "../SliderMediaWrapper/SliderMediaWrapper";

import "./Featured.scss";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <HiArrowNarrowRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <HiArrowNarrowLeft />
    </div>
  );
}
const toMediaCategoryShape = ({ id, title_en, title_ar, media = [] }) => ({
  id,
  title_en,
  title_ar,
  media: media.map((m) => ({ ...m, category_media_id: id })),
});

export default function Featured({ data = {} }) {
  const slideRef = useRef(null);
  const history = useHistory();
  const [playingVideo, setPlayingVideo] = useState(null);
  const settingsGallery = {
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    // centerMode: true,
    beforeChange: () => {
      setPlayingVideo(null);
    },

    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const { media_category = [], events = [], worldInformation = [] } = data;

  const mediaCategory = [
    ...media_category,
    toMediaCategoryShape({
      id: "events",
      title_en: "Events",
      title_ar: "فعاليات",
      media: events,
    }),
    toMediaCategoryShape({
      id: "world_information",
      title_en: "Around the world",
      title_ar: "حول العالم",
      media: worldInformation,
    }),
  ];

  const handleMediaClick = (category) => {
    if (category?.id === 7) {
      history.push(`/article/${category?.media[0]?.id}`);
    } else if (category?.id === "events") {
      history.push(`/events/${category?.media[0]?.id}`);
    } else if (category?.id === "world_information") {
      history.push(`/world_information/${category?.media[0]?.id}`);
    } else {
      history.push("/media", { activeTabKey: `${category?.id}` });
    }
  };

  const handleStopVideo = () => {
    setPlayingVideo(null);
    slideRef.current.slickPlay();
  };

  const handlePlayVideo = (id) => {
    setPlayingVideo(id);
    slideRef.current.slickPause();
  };

  return (
    <div className="featured_media">
      <Slider ref={slideRef} {...settingsGallery} className="slider-hero">
        {mediaCategory?.map((cat) =>
          cat?.media?.map((media) => (
            <div key={`${cat?.id}-${media?.id}`}>
              <SliderMediaWrapper
                media={media}
                playingVideo={playingVideo === `${cat?.id}-${media?.id}`}
                handleStopVideo={handleStopVideo}
                handlePlayVideo={() =>
                  handlePlayVideo(`${cat?.id}-${media?.id}`)
                }
                handleMediaClick={() => handleMediaClick(cat)}
                wrapperClassNames={"slide_content_holder"}
                mediaClassNames={"featured_media_holder"}
              />
            </div>
          ))
        )}
      </Slider>
    </div>
  );
}
