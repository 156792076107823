import logo from "./logo.svg";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "antd/dist/antd.css";
import "aos/dist/aos.css";
import "./styles/globals.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import Routes from "./Routes";
import Header from "./public/components/Header/Header";
import Sidenav from "./public/components/Sidenav/Sidenav";
import Menu from "./public/components/Menu/Menu";
import { useEffect, useState } from "react";
import NewsLetter from "./public/components/NewsLetter/NewsLetter";
import ButtonFloating from "./public/components/ButtonFloating/ButtonFloating";
import AOS from "aos";
import en from "./public/localization/en";
import ar from "./public/localization/ar";
import { useSelector } from "react-redux";
import FloatingWhatsup from "./public/components/FloatingWhatsup/FloatingWhatsup";

function App() {
  const { currentLang } = useSelector((state) => state.user);
  const t = currentLang === "en" ? en : ar;

  const [menuVisible, setMenuVisible] = useState(false);
  useEffect(() => {
    AOS.init({
      // easing: "ease-out-cubic",
      // once: true,
      // duration: 400,
      offset: 120,
    });
    document.getElementsByTagName("html")[0].setAttribute("lang", currentLang);
  }, []);
  return (
    <>
      <Header />
      <div className="app-wrapper">
        <Sidenav visible={menuVisible} handleMenu={setMenuVisible} t={t} />
        <Menu visible={menuVisible} setVisible={setMenuVisible} t={t} />
        <main className="app-main-content">
          {/* <Switch> */}
          <Routes />
          {/* </Switch> */}
        </main>
      </div>
      <NewsLetter t={t} />
      <ButtonFloating t={t} />
      <FloatingWhatsup />
    </>
  );
}

export default App;
