import styles from "./TabHead.module.scss";

function TabHead({ title, count, active }) {
  return (
    <div
      className={`${styles.tabs_tab_content} ${active ? styles.active : ""}`}
    >
      <h4>{title}</h4>
      <p>{count}</p>
    </div>
  );
}

export default TabHead;
