import { useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { useLocalization } from "../../hooks/useLocalization";
import { Row, Col, Image } from "antd";
import Slider from "react-slick";
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
import Footer from "../../public/components/Footer/Footer";
import SliderMediaWrapper from "../../public/components/SliderMediaWrapper/SliderMediaWrapper";

import styles from "./SingleMedia.scss";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <HiArrowNarrowRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <HiArrowNarrowLeft />
    </div>
  );
}

export default function SingleMedia(props) {
  const [playingVideo, setPlayingVideo] = useState(null);
  const { t, currentLang } = useLocalization();
  const params = useParams();
  const [media, setMedia] = useState({});
  const slideRef = useRef(null);
  const settings = {
    // dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    // centerMode: true,
    beforeChange: () => {
      setPlayingVideo(null);
    },

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (props?.type === "events") {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/events/${params?.id}`)
        .then((response) => response.json())
        .then((data) => {
          setMedia(data?.data?.event);
        });
    } else {
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/worldInformation/${params?.id}`
      )
        .then((response) => response.json())
        .then((data) => {
          setMedia(data?.data?.worldInformation);
        });
    }
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);

  const handleStopVideo = () => {
    setPlayingVideo(null);
    slideRef.current.slickPlay();
  };

  const handlePlayVideo = (id) => {
    setPlayingVideo(id);
    slideRef.current.slickPause();
  };

  return (
    <>
      <div className="single_media_page">
        <Helmet>
          <title>
            {currentLang === "en" ? media?.title_en : media?.title_ar}
          </title>
          <meta
            name="title"
            content={currentLang === "en" ? media?.title_en : media?.title_ar}
          />
          <meta
            name="description"
            content={
              currentLang === "en"
                ? media?.description_en
                : media?.description_ar
            }
          />
          <meta
            property="og:title"
            content={currentLang === "en" ? media?.title_en : media?.title_ar}
          />
          <meta
            property="og:description"
            content={
              currentLang === "en"
                ? media?.description_en
                : media?.description_ar
            }
          />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
        <div className="container">
          <div className={"hero_content"}>
            <div>
              <div className={"cover_holder"} data-aos="fade-up">
                <div className="media-img-holder">
                  <img src={media?.image} alt="media" />
                </div>
              </div>
            </div>
            <div>
              <div className={"text_holder"} data-aos="fade-down">
                <div className={"text_content"}>
                  <br></br>
                  <h4>
                    {currentLang === "en" ? media?.title_en : media?.title_ar}
                  </h4>
                  <br></br>
                  {/* <hr className="title-underline"></hr> */}
                  <br></br>
                  <p>
                    
                    {currentLang === "en"
                      ? media?.description_en
                      : media?.description_ar}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {props.type === "world_information" && (
            <div className="media_content_body">
              {React.Children.toArray(
                media?.gallery?.map((gallery) => (
                  <div className="image_text_holder">
                    <div className={"hero_content"}>
                      <div className="row">
                        <div className="col">
                          <div
                            className={"cover_holder"}
                            data-aos={
                              currentLang === "en" ? "fade-left" : "fade-right"
                            }
                          >
                            <div className="media-img-holder">
                              <img src={gallery?.image} alt="media" />
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div
                            className={"text_holder"}
                            data-aos={
                              currentLang === "en" ? "fade-right" : "fade-left"
                            }
                          >
                            <div className={"text_content"}>
                              <p>
                                {currentLang === "en"
                                  ? gallery?.text_en
                                  : gallery?.text_ar}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
          {props.type === "events" && (
            <div className="slider_holder" data-aos="zoom-in">
              <h2 className={"title_2"} data-aos="fade-left">
                {t.gallery}
              </h2>
              <Slider ref={slideRef} {...settings} className="slider-hero">
                {React.Children.toArray(
                  media?.gallery?.map((gallery, idx) => (
                    <div key={`${gallery?.id}-${idx}`}>
                      <SliderMediaWrapper
                        media={gallery}
                        playingVideo={playingVideo === `${gallery?.id}-${idx}`}
                        handleStopVideo={handleStopVideo}
                        handlePlayVideo={() =>
                          handlePlayVideo(`${gallery?.id}-${idx}`)
                        }
                        handleMediaClick={() => {}}
                        wrapperClassNames={"slide_content_holder"}
                        mediaClassNames={"media_gallery_holder"}
                      />
                    </div>
                  ))
                )}
              </Slider>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
