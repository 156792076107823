export default {
  home: "الرئيسية",
  about_aitham: "عن هيثم",
  about: "عن هيثم",
  HICGroup: "HIC مجموعة",
  services: "خدمات",
  our_services: "خدماتنا",
  get_to_know_all_of_our_services: "تعرف على مجموعة خدماتنا",
  get_to_know_our_group_companies: "تعرف على شركاتنا",
  FindUsOn: "تابعنا ",
  contactus: "تواصل معنا",
  Contact: "تواصل  مع ",
  media: "ميديا",
  kaifa: "كايفا",
  vedio: "فيديو",
  shipping: "الشحن",
  currency: "العملات",
  gallery: "معرض الصور",
  FeaturedNews: "أحدث الأخبار",
  MoreVideos: "فيديوهات اكثر",
  MoreNews: "المزيد من الأخبار",
  MoreArticls: "المزيد من المقالات",
  pleaseselect: "الرجاء تحديد ",
  Pleaseinput: "الرجاء ادخال",
  Emailvalid: "عنوان البريد الإلكتروني ليس بريدًا إلكترونيًا صالحًا",
  EmailAddress: "عنوان البريد الإلكتروني",
  Phonenumber: "رقم الهاتف",
  name: "الاسم",
  article: "مقال",
  WhatsApp: "واتس اب",
  Like: "إعجاب",
  send: "إرسال",
  message: "الرساله",
  ourservices: "خدماتنا",
  hic_offers: "تقدم HIC خدمات شاملة",
  services_description: "تعرف على مجموعة خدماتنا",
  more: "أكثر",
  hic_services: "خدمات HIC",
  his_group: "مجموعة HIC",
  his_group_description: "تعرف على شركاتنا",
  follow_us: "تابعنا ",
  find_us_on_social_media: "منصات التواصل الإجتماعى",
  follow_us_description: "تابعنا الآن",
  haitham: "هيثم",
  in_china: "في الصين",
  follow: "متابعة",
  follow_us_now: "تابعنا الآن",
  latest_media: "أحدث الفيديوهات",
  all_media: "كل الميديا",
  haitham_social_in_numbers: "ارقام هيثم على مواقع التواصل الاجتماعية",
  k: "ألف",
  m: "مليون",
  Followers: "متابع",
  instagram_followers: "متابعين على انيستجرام",
  facebook_followers: "متابعين على فيسبوك",
  youtube_subscribers: "مشتركين على يوتيوب",
  contact_us: "تواصل معنا",
  full_name: "الاسم بالكامل",
  email: "بريد الالكتروني",
  menu: "القائمة",
  KAIFANumbers: "ارقام كايفا",
  scroll_up: "الأعلى",
  scroll_down: "الأسفل",
  subscribe_to_our_newsletter: "اشترك في قائمتنا البريدية",
  enter_your_email_address: "أدخل عنوان بريدك الالكتروني",
  subscribe: "اشتراك",
  egyptCairo: "مصر - القاهرة ",
  haitham_in_china: "هيثم في الصين",
  hic_group: "HIC مجموعة",
  locations: "مواقعنا",
  egypt: "مصر",
  PrivacyTerms: "سياسة الخصوصية · الشروط والأحكام",
  rightsreserved: "جميع الحقوق محفوظة 2022. © HIC",
  china: "الصين",
  chinaGuangzhou: "الصين - جوانزو",
  chinaYiwu: "الصين - إيوو",
  download_our_app: "تنزيل التطبيق",
  available_soon: "قريبا",
  newsletter: "القائمة البريدية",
  subscribe_to_our_email_list: "اشترك في قائمتنا البريدية الآن",
  china_guangzhou: "الصين - جوانزو",
  china_yiwu: "الصين - إيوو",
  address: "العنوان",
  FOR_egypt_address: "11ش محمد متولى الشعراوى، شيراتون، القاهرة",
  FOR_china_guangzhou_address:
    "Room 3A01, R&F International Shoe Trade Center,No. 37 Huanshi West Road, Liwan District.",
  FOR_china_yiwu_address:
    "12A03, International Trade Building, No. 999 Chouzhou North road,Choucheng Street.",
  working_hours: "مواعيد العمل",
  FOR_working_hours: "09:00 am – 05:00 pm",
  tel: "هاتف",
  FOR_egypt_tel: "+2 02 22687802 - +2 02 22687803",
  whats_app: "what’s app",
  FOR_whats_app: "+8618529461301",
  FOR_email: "info@haithaminchina.com",
  location_on_map: "الموقع على الخريطة",
  FOR_about_title_1: "عضو مجلس ادارة منتدي رجال الاعمال العرب بالصين",
  FOR_about_title_2: "    رئيس مجلس ادارة مجموعة شركات HIC",
  FOR_about_title_3:
    "عضو الشعبة العامة للمستوردين بالاتحاد العام للغرف التجارية",
  FOR_about_title_4: "عضو شعبة الملاحة بالغرفة التجارية  بالاسكندرية",
  FOR_about_title_5:
    'حاصل على درجة  الماجستير  فى "إدارة الاعمال  والتسويق الدولى " عام 2019 ',
  FOR_about_hitham_text_1:
    "هيثم طلحة رائد اعمال مصري تخرج من كلية الالسن جامعة عين شمس قسم اللغة الاسبانية",
  FOR_about_hitham_text_2:
    "بدأ العمل في مجال التجارة سنة 2003 في مجال الشحن الدولي والاستيراد والتصدير في الصين ومصركما يعرف اعلاميا باسم هيثم في الصينHIC وهو صاحب مجموعة شركات ",
  FOR_about_hitham_text_3:
    "ومنشئ مجموعة من منصات التواصل الاجتماعي تحت نفس المسمي باكثر من 2 مليون متابع حول العالم  والتي تقدم محتوي في مجال التجارة والاستيراد والتصدير بشكل عام",
  Haitham_certificates: "شهادات هيثم",
  Like: "اعجبني",
  subscribe: "اشتراك",
  Follow: "متابعة",
  Followers: "متابعون",
  Haitham_Socials: "هيثم ميديا",
  china_gate: "بوابة الصين",
  china: " الصين",
  gate: "بوابة ",
  Allyouneedtoknowaboutyourvisittochina: "كل ما تريد معرفته عن زيارتك للصين",
  showMore: "عرض المزيد",
  showDetails: "عرض التفاصيل",
  chinaNews: "أخبار الصين",
  moreWorldNews: "المزيد من الأخبار حول العالم",
  moreChinaNews: "المزيد من الأخبار عن الصين",
  worldNews: "أخبار حول العالم",
  buy: "شراء",
  sell: "بيع",
  all_news: "كل الأخبار",
  event: "فعالية",
  topic: "موضوع",
  MoreEvents: "المزيد من الفعاليات",
  MoreTopics: "المزيد من المواضيع",
  Featured: "الأحدث",
};
