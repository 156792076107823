import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/ar-ly";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useLocalization } from "../../../hooks/useLocalization";

import styles from "./TimeDisplay.scss";

dayjs.extend(localizedFormat);
export default function TimeDisplay({ date }) {
  const { currentLang } = useLocalization();
  console.log({ currentLang });

  if (currentLang === "en") {
    date = dayjs(date).locale(currentLang);
  } else {
    date = dayjs(date).locale("ar-ly");
  }
  
  return (
    <time className="time_display_container">
      <ClockCircleOutlined />
      {date.format("dddd, D MMMM, YYYY - h:mm A")}
    </time>
  );
}
