import { BsChevronDoubleDown } from "react-icons/bs";
import { Button, Col, Image, Row, Tooltip } from "antd";
import React from "react";
import Footer from "../../public/components/Footer/Footer";
import PlacesWrapper from "../../public/components/PlacesWrapper/PlacesWrapper";
import SinglePlace from "../../public/components/SinglePlace/SinglePlace";
import ph_1 from "../../public/images/ph-1.jpg";
import ph_2 from "../../public/images/ph-2.jpg";
import ph_3 from "../../public/images/ph-3.jpg";
import ph_4 from "../../public/images/ph-4.jpg";
import { FaUsers, FaGlobeAsia } from "react-icons/fa";
import Slider from "react-slick";
import styles from "./ChinaGate.module.scss";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import en from "../../public/localization/en";
import ar from "../../public/localization/ar";

function ChinaGate() {
  const placesWrapper = useRef();
  const placesWrapper2 = useRef();
  const [chinaData, setchinaData] = useState(null);
  const { currentLang } = useSelector((state) => state.user);
  const t = currentLang === "en" ? en : ar;

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/china-gate`)
      .then((response) => response.json())
      .then((data) => setchinaData(data?.data));

    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);
console.log("chinaData",chinaData)
  return (
    <>
      <div className={styles.china_gate_page}>
        <div className={styles.china_gate_inner} 
                  style={{
                    backgroundImage: `url(${chinaData?.china_gate?.cover_image})`, 
                  }}
                  >
          <section className={styles.cover_wrapper} 
          // style={{
          //   backgroundImage: `linear-gradient(
          //   to top right,
          //   rgba(102, 18, 18, 0.6),
          //   rgba(0, 0, 0, 0.05)
          // ),url(${chinaData?.china_gate?.cover_image})`, 
          // }}
          >
            <div className={styles.cover_inner}>
              <Row gutter={[30, 40]} align="top" style={{ flex: 1 }}>
                <Col xs={24} lg={9}>
                  <div className={styles.text_wrapper}>
                    <div className="china_gate_title">
                      <span className="underline">{t.china}</span>
                      <span className="bold">{t.gate}</span>
                    </div>
                    <div className="place_descraption_style">
                      <div className="head">
                        <span className="color">
                          {t.Allyouneedtoknowaboutyourvisittochina}
                        </span>
                        <span>
                          {currentLang === "en"
                            ? chinaData?.china_gate?.description_en
                            : chinaData?.china_gate?.description_ar}
                        </span>
                      </div>
                    </div>
                    <div className={styles.tooltip_info_wrapper}>
                      {currentLang === "en" ? 
                      <div className={styles.tooltip_info_inner}>
                        {chinaData?.icons?.map((icon) => (
                          <Tooltip
                            className={styles.tooltip_hero}
                            title={
                              <>
                                <h4
                                  style={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#4465a3",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {/* {currentLang === "en" ? icon?.description_en : icon?.description_ar } */}
                                </h4>
                                <span
                                  style={{
                                    fontSize: 14,
                                    color: "#111",
                                    lineHeight: 1.4,
                                  }}
                                >
                                  {currentLang === "en"
                                    ? icon?.description_en
                                    : icon?.description_ar}
                                </span>
                              </>
                            }
                            // placement={
                            //   currentLang === "en"
                            //     ? "bottomRight"
                            //     : "bottomLeft"
                            // }
                            placement="bottomLeft"
                            arrowPointAtCenter
                            overlayInnerStyle={{
                              backgroundColor: "#ffffffaf",
                              borderRadius: 10,
                              width: "42rem",
                              padding: 10,
                            }}
                          >
                            <img
                              src={icon?.image}
                              width={40}
                              height={40}
                              style={{ maxWidth: 40 }}
                              alt=""
                            />
                          </Tooltip>
                        ))}
                      </div> : 
                                            <div className={styles.tooltip_info_inner}>
                                            {chinaData?.icons?.map((icon) => (
                                              <Tooltip
                                                className={styles.tooltip_hero}
                                                title={
                                                  <>
                                                    <h4
                                                      style={{
                                                        fontSize: 16,
                                                        fontWeight: 600,
                                                        color: "#4465a3",
                                                        textTransform: "capitalize",
                                                      }}
                                                    >
                                                      {/* {currentLang === "en" ? icon?.description_en : icon?.description_ar } */}
                                                    </h4>
                                                    <span
                                                      style={{
                                                        fontSize: 14,
                                                        color: "#111",
                                                        lineHeight: 1.4,
                                                      }}
                                                    >
                                                      {currentLang === "en"
                                                        ? icon?.description_en
                                                        : icon?.description_ar}
                                                    </span>
                                                  </>
                                                }
                                                // placement={
                                                //   currentLang === "en"
                                                //     ? "bottomRight"
                                                //     : "bottomLeft"
                                                // }
                                                placement="bottomRight"
                                                arrowPointAtCenter
                                                overlayInnerStyle={{
                                                  backgroundColor: "#ffffffaf",
                                                  borderRadius: 10,
                                                  width: "42rem",
                                                  padding: 10,
                                                }}
                                              >
                                                <img
                                                  src={icon?.image}
                                                  width={40}
                                                  height={40}
                                                  style={{ maxWidth: 40 }}
                                                  alt=""
                                                />
                                              </Tooltip>
                                            ))}
                                          </div>
                      }
                    </div>
                  </div>
                </Col>
                <Col xs={24} lg={15}>
                  <div className={styles.places_wrapper}>
                    <div className={styles.places_list}>
                      <Row gutter={[30, 40]} justify="center">
                        {chinaData?.states &&
                          chinaData?.states?.slice(0, 3)?.map((s) => (
                            <Col key={s?.id}>
                              <SinglePlace
                                style={{ height: "40rem", width: "20rem" }}
                                id={s?.id}
                                link
                                img={s?.icon_image}
                                title={{
                                  ar:
                                    currentLang === "en"
                                      ? s?.title_en
                                      : s?.title_ar,
                                  en: "",
                                }}
                              />
                            </Col>
                          ))}
                      </Row>
                    </div>
                  </div>
                  {chinaData?.ads[0] && (
                    <a
                      href={chinaData?.ads[0] && chinaData?.ads[0]?.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ADS-place"
                      style={{ marginTop: "8rem" }}
                    >
                      <img
                        src={chinaData?.ads[0] && chinaData?.ads[0]?.image}
                        alt=""
                      />
                    </a>
                  )}
                </Col>
              </Row>
              <Button
                type="link"
                className={styles.btn_show_more}
                style={{ marginTop: 60 }}
                onClick={() =>
                  placesWrapper.current.scrollIntoView({
                    behavior: "smooth",
                  })
                }
              >
                {t.showMore}

                <span>
                  <BsChevronDoubleDown />
                </span>
              </Button>
            </div>
          </section>
          {chinaData?.states?.length > 2 && (
            <section
              className={styles.places_wrapper_holder}
              ref={placesWrapper}
              // style={{
              //   backgroundImage: `linear-gradient(
              //   to top right,
              //   rgba(102, 18, 18, 0.6),
              //   rgba(0, 0, 0, 0.05)
              // ),url(${chinaData?.china_gate?.cover_image})`, 
              // }}
            >
              <PlacesWrapper list={chinaData?.states?.slice(3, 13) || []} />
              {chinaData?.states?.length > 9 && (
                <PlacesWrapper list={chinaData?.states?.slice(13, 23) || []} />
              )}
              {chinaData?.states?.length > 19 && (
                <PlacesWrapper list={chinaData?.states?.slice(23, 33) || []} />
              )}
              {/* {chinaData?.states?.length > 9 && 
            <Button
              type="link"
              className={styles.btn_show_more}
              style={{ marginTop: 60 }}
              onClick={() =>
                placesWrapper2.current.scrollIntoView({
                  behavior: "smooth",
                })
              }
            >
              {t.showMore}
              <span>
                <BsChevronDoubleDown />
              </span>
            </Button>
            } */}
            </section>
          )}

          {/* {chinaData?.states?.length > 9 &&
          <section className={styles.places_wrapper_holder} ref={placesWrapper2}>
            <PlacesWrapper list={chinaData?.states?.slice(10, 20) || []} />
          </section>
           } */}
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default ChinaGate;
