import { Col, Row } from "antd";
import ReactPlayer from "react-player";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { Link, withRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import en from "../../../localization/en";
import ar from "../../../localization/ar";
import styles from "./LiveStreamingTab.module.scss";
import MoreVideos from "../../MoreVideos/MoreVideos";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { useHistory } from "react-router-dom";
function LiveStreamingTab({ media }) {
  const [playVideo, setPlayVideo] = useState(false);
  const { currentLang } = useSelector((state) => state.user);
  const history = useHistory();
  const t = currentLang === "en" ? en : ar;
  const handlePlayVideo = () => {
    setPlayVideo(true);
  };
  const handleVideoStop = () => {
    setPlayVideo(false);
  };
  const handleMediaClick = () => {
    if (media?.id === 7) {
      history.push(`/article/${media?.media[0]?.id}`);
    } else if (media?.id === "events") {
      history.push(`/events/${media?.media[0]?.id}`);
    } else if (media?.id === "world_information") {
      history.push(`/world_information/${media?.media[0]?.id}`);
    }
  };

  return (
    <div className={styles.tab_content_holder}>
      <h3 className={styles.title_hero} data-aos="fade-left">
        {currentLang === "en" ? media?.title_en : media?.title_ar}
      </h3>
      {console.log("mediamedia", media.title_en)}
      <div className={styles.tab_content}>
        <div className={styles.tab_content_row}>
          {media?.media?.length > 0 && (
            <div
              className={styles.video_holder}
              data-aos="fade-up"
              onClick={handleMediaClick}
            >
              {media?.media[0]?.image ? (
                <div className={styles["media-img-holder"]}>
                  <img
                    src={media?.media[0]?.image}
                    alt={media?.media[0]?.image?.title_ar}
                  />
                </div>
              ) : (
                <ReactPlayer
                  width="100%"
                  height="100%"
                  volume={0.5}
                  // playing={playVideo}
                  // controls={playVideo}
                  // onEnded={handleVideoStop}
                  // onPause={handleVideoStop}
                  // url={`${media[0]?.video}`}
                  url={media?.media[0]?.video || media?.media[0]?.facebook}
                />
              )}
              {/* <BsFillPlayCircleFill
                className={styles.play_icon}
                title="Play video"
                style={{ display: playVideo ? "none" : "block" }}
                onClick={handlePlayVideo}
              /> */}
            </div>
          )}
          {media?.media?.length > 0 && (
            <div className={styles.text_holder} data-aos="fade-down">
              <div className={styles.text_content}>
                <br></br>
                <h4>
                  {currentLang === "en"
                    ? media?.media[0]?.title_en
                    : media?.media[0]?.title_ar}
                </h4>
                <br></br>
                {/* <hr className="title-underline"></hr> */}
                {+media?.id === 7 ? null : (
                  <p>
                    {currentLang === "en"
                      ? parse(media?.media[0]?.description_en || "")
                      : parse(media?.media[0]?.description_ar || "")}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        {media?.media?.length > 0 && (
          <div className={styles.more_videos_holder}>
            <MoreVideos list={media?.media?.slice(1)} id={media?.id} classNam={" " + media.title_en} />
          </div>
        )}
      </div>
    </div>
  );
}

export default LiveStreamingTab;
