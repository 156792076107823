import { Button, Col, Form, Input, Row, Image, message } from "antd";
import { Link, withRouter } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";
import {
  FaFacebookF,
  FaYoutube,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaTiktok,
} from "react-icons/fa";

import logoImg from "../../images/Logo.png";
import app1 from "../../images/app1.svg";
import app2 from "../../images/app2.svg";
import app3 from "../../images/app3.svg";

import en from "../../localization/en";
import ar from "../../localization/ar";

import styles from "./Footer.module.scss";
import { useSelector } from "react-redux";
import { useState } from "react";
import axios from "axios";

function Footer() {
  const { currentLang } = useSelector((state) => state.user);
  const t = currentLang === "en" ? en : ar;

  const [registerLoading, setloginLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setloginLoading(true);
    let body = new FormData();
    body.append("email", values?.email);

    axios({
      method: "post",
      Accept: "application/json",
      url: `${process.env.REACT_APP_API_BASE_URL}/api/newSubscriber`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: body,
    })
      .then(function (response) {
        setloginLoading(false);
        if (response?.data?.success) {
          message.success("subscribed successfully");
          form.resetFields();
        }
      })
      .catch(function (error) {
        setloginLoading(false);
        // message.error(error?.response?.data?.errors?.phone[0]);
      });
  };

  return (
    <footer className={styles.footer}>
      <div className={`container ${styles.container}`}>
        <Row
          justify="space-between"
          align="top"
          gutter={[60, 30]}
          data-aos="fade-up"
        >
          <Col xs={24} sm={12} lg={8} xl={4}>
            <ul className={`${styles.list} ${styles.list_company}`}>
              <li className={styles.list_title}>{t.haitham_in_china}</li>
              <li>
                <Link to="/about">
                  <a>{t.about_aitham}</a>
                </Link>
              </li>
              <li>
                <Link to="/hic-group/1">
                  <a>{t.his_group}</a>
                </Link>
              </li>
              {/* <li>
                <Link to="/">
                  <a>{t.services}</a>
                </Link>
              </li> */}
              <li>
                <Link to="/media">
                  <a>{t.media}</a>
                </Link>
              </li>
              <li>
                <Link to="/kaifa">
                  <a>{t.kaifa}</a>
                </Link>
              </li>
              <li>
                <Link to="/china-gate">
                  <a>{t.china_gate}</a>
                </Link>
              </li>
              <li>
                <Link to="/contact-us">
                  <a>{t.contactus}</a>
                </Link>
              </li>
            </ul>
          </Col>
          <Col xs={24} sm={12} lg={8} xl={4}>
            <ul className={`${styles.list} ${styles.list_office}`}>
              <li className={styles.list_title}>{t.locations}</li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.google.com/maps/search/ASMA+MARINE+Global+Logistics+and+Freight+Forwarder/@30.1021432,31.3808091,17z?hl=en-EG"
                >
                  {t.egypt}
                  <FiArrowUpRight />
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps/search/Fuli+Int'l.+Shoes+Industry+Bus.+Ctr,+北京路商业区+Li+Wan+Qu,+Guang+Zhou+Shi,+Guang+Dong+Sheng,+China/@23.1420555,113.2407303,17z?hl=en-EG"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t.china_guangzhou}
                  <FiArrowUpRight />
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps/search/International+Trade+Building,+No.+999+Chouzhou+North+road,+Futian+Street,+Yiwu,+Zhejiang,+China/@29.3336655,120.1013464,17z?hl=en-EG"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t.china_yiwu}
                  <FiArrowUpRight />
                </a>
              </li>
              {/* <li>
                <a href="/">
                  {t.egypt}
                  <FiArrowUpRight />
                </a>
              </li> */}
            </ul>
          </Col>
          <Col xs={24} sm={12} lg={8} xl={4}>
            <ul className={`${styles.list} ${styles.download_app}`}>
              <li className={styles.list_title}>{t.download_our_app}</li>
              <li>
                <div className={styles.content_holder}>
                  <ul className={styles.list_apps}>
                    <li>
                      <Link href="/">
                        <a>
                          <Image preview={false} src={app1} />
                        </a>
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.haithaminchina.hic"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <Image preview={false} src={app2} />
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </Col>
          <Col xs={24} sm={12} lg={8} xl={6}>
            <ul className={`${styles.list} ${styles.list_news_letter}`}>
              <li className={styles.list_title}>{t.newsletter}</li>
              <li>{t.subscribe_to_our_email_list}</li>
              <li
                className={`footer_news_letter_form_holder ${styles.form_holder}`}
              >
                <Form name="basic" form={form} onFinish={onFinish}>
                  <Form.Item name="email" rules={[{ type: "email" }]}>
                    <Input placeholder={`${t.Pleaseinput} ${t.email}`} />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      loading={registerLoading}
                      type="link"
                      htmlType="submit"
                    >
                      {t.subscribe}
                    </Button>
                  </Form.Item>
                </Form>
              </li>
            </ul>
          </Col>
          <Col xs={24} sm={12} lg={8} xl={6}>
            <div className={styles.brand}>
              <div className={styles.img_holder}>
                {/* <Image src="/public/images/Logo.png" width={190} height={140} /> */}
                <Image
                  preview={false}
                  src={logoImg}
                  width={190}
                  height={140}
                  alt="logo"
                />
                {/* <img src={logo}  width={190} height={140} alt="" /> */}
              </div>
              <ul className={`${styles.list} ${styles.list_follow_us}`}>
                {/* <li className={styles.list_title}>follow us</li> */}
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/HaithaminChina"
                  >
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.youtube.com/c/haithaminchina/featured"
                  >
                    <FaYoutube />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.twitter.com/HaithaminChina"
                  >
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/haithaminchina"
                  >
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/71660921"
                  >
                    <FaLinkedinIn />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.tiktok.com/@haithaminchina"
                  >
                    <FaTiktok />
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <div className={styles.copyright}>
          <Row justify="space-between" gutter={[60, 20]}>
            <Col>
              <p>{t.PrivacyTerms}</p>
            </Col>
            <Col>
              <p>{t.rightsreserved}</p>
            </Col>
          </Row>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
