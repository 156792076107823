import styles from "./SliderHero.module.scss";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import { HiArrowNarrowLeft, HiArrowNarrowRight } from "react-icons/hi";
import { useRef } from "react";
import { useState, useEffect } from "react";
import { Image } from "antd";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <HiArrowNarrowRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <HiArrowNarrowLeft />
    </div>
  );
}


function SliderHero({ t }) {
  const slideRef = useRef(null);
  const vedioRef = useRef(null);
  const [home_slider, setSlider] = useState([]);
  const [isVideoPlay, setisVideoPlay] = useState(true);
  const [isVideoLoop, setisVideoLoop] = useState(true);
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    afterChange : (current) => {
      // console.log("wwwwwwwwwwwwwwwwwwwwwwwwww", current)   
      // console.log("wwwwwwwwwwwwwwwwwwwwwwwwww", home_slider.length)   
      setisVideoPlay(false);
      slideRef.current.slickPlay();
      if(+current === home_slider.length - 1){
        setTimeout(() => { 
          setisVideoPlay(false);
          slideRef.current.slickGoTo(0);
         }, 1000)
      }
    }
  };
  
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/slider`)
      .then((response) => response.json())
      .then((data) => {
        setSlider(data?.data?.slides);
        // console.log(slideRef.current, "slideRef.current");
        setTimeout(() => { 
          setisVideoPlay(true);
        }, 800)
        // slideRef.current.slickPlay();
        // setTimeout(() => { slideRef.current.slickNext() }, 1000)
      });
  }, []);

  useEffect(() => {
        window.addEventListener("scroll", handler);
        return () => window.removeEventListener("scroll", handler);
  }, [])
  
  const handler = () => {
    // console.log(slideRef)
    // console.log(slideRef.current)
      if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
        ) {
          // console.log("stoppppppppppp")
          setisVideoPlay(false);
          // console.log("isVideoPlay", isVideoPlay)
          slideRef.current.slickPlay();
        }
    };

  const handleVideoEnd = () => {
    // console.log(slideRef, "slideRef");
    // console.log(slideRef.current, "slideRef.current");
    setisVideoPlay(false);
    slideRef.current.slickPlay();
  };

  return (
    <section className="section-vh home-slider-holder">
      <section className={`slider_hero ${styles.slider_hero}`}>
        <Slider ref={slideRef} {...settings}>
          {home_slider?.map((slide) => {
            if (slide?.video) {
              return (
                <div key={slide?.id}>
                  <div
                    className={styles.slide_content_holder}
                    data-aos="zoom-in"
                  >
                    <ReactPlayer
                      ref={vedioRef}
                      width="100%"
                      height="unset"
                      volume={0.4}
                      controls={true}
                      // light={true}
                      // pip={false}
                      onEnded={handleVideoEnd}
                      onStart={() => slideRef.current.slickPause()}
                      onPlay={() => {
                        setisVideoPlay(true);
                        slideRef.current.slickPause();
                      }}
                      onPause={() => {
                        setisVideoPlay(false);
                        slideRef.current.slickPause();
                      }}
                      playing={isVideoPlay}
                      // stopOnUnmount={true}
                      // loop={isVideoPlay}
                      // previewTabIndex
                      // light
                      // muted
                      // loop
                      url={slide?.video}
                      // url="https://github.com/momenfc/images-on-server/blob/5cb29d5e7ab54dff03b4492e5b16d7082cccdb4f/Watch.mp4?raw=true"
                    />
                  </div>
                </div>
              );
            } else {
              return (
                <div key={slide?.id}>
                  <div className={styles.slide_content_holder}>
                    {/* {slide?.image && (
                  <Image width={1880} height={960} src={slide?.image} />
                )} */}
                    {slide?.image && (
                      <Image
                        // width={1880}
                        // height={980}
                        src={slide?.image}
                        alt={slide?.image}
                        preview={false}
                      />
                    )}
                  </div>
                </div>
              );
            }
          })}
        </Slider>
      </section>
    </section>
  );
}

export default SliderHero;
