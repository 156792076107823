import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import Footer from '../../public/components/Footer/Footer'
import en from "../../public/localization/en";
import ar from "../../public/localization/ar";
import { Breadcrumb } from 'antd';
import { AiOutlineLine } from "react-icons/ai";
import { Link, useParams } from 'react-router-dom';
import parse from 'html-react-parser';


export default function SingleArticle() {
  const [brandData, setBrandData] = useState(null);
    const { currentLang } = useSelector((state) => state.user);
    const t = currentLang === "en" ? en : ar;
    const params = useParams();

    useEffect(() => {
        const body = document.querySelector("#root");
        body.scrollIntoView(
          {
            behavior: "smooth",
          },
          500
        );
        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/media/${params?.id}`)
          .then((response) => response.json())
          .then((data) => setBrandData(data?.data?.media));
      }, []);

  return (
    <>
    <div className={`article-page`}>
      <Helmet>
        <title>
          {currentLang === "en" ? brandData?.title_en : brandData?.title_ar}
        </title>
        <meta
          name="title"
          content={
            currentLang === "en" ? brandData?.title_en : brandData?.title_ar
          }
        />
        <meta
          name="description"
          content={
            currentLang === "en"
              ? brandData?.description_en
              : brandData?.description_ar
          }
        />
        <meta
          property="og:title"
          content={
            currentLang === "en" ? brandData?.title_en : brandData?.title_ar
          }
        />
        <meta
          property="og:description"
          content={
            currentLang === "en"
              ? brandData?.description_en
              : brandData?.description_ar
          }
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="container">
        <div className="article-page-holder container">
          <Breadcrumb
            data-aos="fade-right"
            className={`breadcrumb`}
            separator={<AiOutlineLine />}
          >
            <Breadcrumb.Item className="breadcrumb-to">
              <Link to="/">{t.home}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumb-to">
              <Link to="/media" className="rtl-text">
                {t.media}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumb-current">
              {currentLang === "en"
                ? brandData?.title_en
                : brandData?.title_ar}
            </Breadcrumb.Item>
          </Breadcrumb>

          <div className="articel-hero-holder">
            <img src={ brandData?.cover_image || brandData?.image} alt="" />
          </div>
          <div className="articel-content-holder">
            <h1 className='article--title'>
            {currentLang === "en" ? brandData?.title_en : brandData?.title_ar}
            </h1>
            <div className="article-desc">
            {currentLang === "en" ? parse(brandData?.description_en  || "") : parse(brandData?.description_ar || "")}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
  )
}
