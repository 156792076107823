import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { useLocalization } from "../../hooks/useLocalization";
import { Helmet } from "react-helmet";
import ImageWithViewer from "../../public/components/ImageWithViewer/ImageWihViewer";
import { Row, Col } from "antd";
import Footer from "../../public/components/Footer/Footer";

import styles from "./ServicePage.scss";

export default function ServicePage() {
  const params = useParams();
  const [service, setService] = useState({});
  const { t, currentLang } = useLocalization();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/service/${params?.id}`)
      .then((response) => response.json())
      .then((data) => {
        setService(data?.data?.service);
      });

    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, [params?.id]);

  return (
    <>
      <div className="service_page container">
        <Helmet>
          <title>
            {currentLang === "en" ? service?.title_en : service?.title_ar}
          </title>
          <meta
            name="title"
            content={
              currentLang === "en" ? service?.title_en : service?.title_ar
            }
          />
          <meta
            name="description"
            content={
              currentLang === "en"
                ? service?.description_en
                : service?.description_ar
            }
          />
          <meta
            property="og:title"
            content={
              currentLang === "en" ? service?.title_en : service?.title_ar
            }
          />
          <meta
            property="og:description"
            content={
              currentLang === "en"
                ? service?.description_en
                : service?.description_ar
            }
          />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
        <div className="service_page--hero">
          <div className="service_page--image" data-aos="fade-down">
            <img alt="service" src={service?.image} />
          </div>
          <div className="service_page--content" data-aos="fade-left">
            <h2 className="service_page--title">
              {currentLang === "en" ? service?.title_en : service?.title_ar}
            </h2>
            <br></br>
            {/* <hr className="title-underline"></hr> */}
            <p className="service_page--description">
              {currentLang === "en"
                ? service?.description_en
                : service?.description_ar}
            </p>
          </div>
        </div>
        <div className="section-vh service_page--gallery">
          <h3 data-aos="fade-up">{t.gallery}</h3>
          <div className="service_page--gallery_grid">
            {React.Children.toArray(
              service?.albums?.map((album) => (
                <ImageWithViewer
                  coverSrc={album?.image}
                  className="service_page--gallery--images"
                  gallery={album?.gallery?.map((g) => g?.image)}
                  data-aos="flip-left"
                />
              ))
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
