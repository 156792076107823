export default {
  home: "Home",
  about_aitham: "About Haitham",
  about: "About Haitham",
  HICGroup: "HIC Group",
  services: "services",
  our_services: "our services",
  get_to_know_all_of_our_services: "Get to know all of our services",
  get_to_know_our_group_companies: "Get to know our group companies",

  contactus: "contact us",
  Contact: "Contact",
  media: "media",
  kaifa: "kaifa",
  vedio: "Video",
  shipping: "shipping",
  currency: "currency",
  gallery: "Gallery",
  FeaturedNews: "Featured News",
  MoreVideos: "More Videos",
  MoreNews: "More News",
  MoreArticls: "More Articls",
  pleaseselect: "please select ",
  Pleaseinput: "Please input",
  Emailvalid: "Email Address is not a valid email",
  EmailAddress: "Email",
  Phonenumber: "Phone",
  name: "name",
  article: "article",
  Like: "Like",
  send: "send",
  message: "message",
  services: "services",
  ourservices: "Our Services",
  hic_offers: "HIC offers end-to-end services",
  services_description: "Get to know all of our services",
  more: "More",
  hic_services: "HIC Services",
  his_group: "HIC Group",
  his_group_description: "Get to know our group companies",
  follow_us: "Follow Us",
  find_us_on_social_media: "Find Us On Social Media",
  follow_us_description: "Follow Us Now.",
  haitham: "Haitham",
  in_china: "in China",
  follow: "Follow",
  KAIFANumbers: " KAIFA In Numbers",
  follow_us_now: "follow us now",
  latest_media: "Latest Media",
  all_media: "All Media",
  haitham_social_in_numbers: " Haitham Social in Numbers",
  k: "K",
  m: "M",
  Followers: "Followers",
  instagram_followers: "Instagram followers",
  facebook_followers: "Facebook followers",
  youtube_subscribers: "Youtube subscribers",
  contact_us: "Contact Us",
  full_name: "Full Name",
  email: "Email",
  menu: "menu",
  scroll_up: "scroll up",
  scroll_down: "Scroll Down",
  subscribe_to_our_newsletter: "subscribe to our newsletter",
  enter_your_email_address: "Enter your email address",
  subscribe: "subscribe",
  egyptCairo: " Egypt - Cairo",

  haitham_in_china: "haitham in china",
  hic_group: "hic group",
  locations: "locations",
  egypt: "Egypt",
  PrivacyTerms: "Privacy Policy · Terms and Conditions",
  rightsreserved: "All rights reserved 2022. © HIC",
  china: "China",
  WhatsApp: "WhatsApp",
  chinaGuangzhou: "China - Guangzhou",
  chinaYiwu: "China - Yiwu",
  FindUsOn: "Find Us On",
  download_our_app: "download our app",
  available_soon: "available soon",
  newsletter: "newsletter",
  subscribe_to_our_email_list: "Subscribe to Our E-Mail List",
  china_guangzhou: "china guangzhou",
  china_yiwu: "china yiwu",
  address: "address",
  FOR_egypt_address: "11 Mohamed Metwaly El-Shaarawy, Sheraton, Cairo.",
  FOR_china_guangzhou_address:
    "Room 3A01, R&F International Shoe Trade Center,No. 37 Huanshi West Road, Liwan District.",
  FOR_china_yiwu_address:
    "12A03, International Trade Building, No. 999 Chouzhou North road,Choucheng Street.",
  working_hours: "working hours",
  FOR_working_hours: "09:00 am – 05:00 pm",
  tel: "tel",
  FOR_egypt_tel: "+2 02 22687802 - +2 02 22687803",
  whats_app: "what’s app",
  FOR_whats_app: "+8618529461301",
  FOR_email: "info@haithaminchina.com",
  location_on_map: "location on map",

  FOR_about_title_1:
    "Member of the Board of Directors of the Arab Businessmen Forum in China",
  FOR_about_title_2: " Chairman of the Group of Companies HIC",
  FOR_about_title_3:
    "Member of the General Division of Importers in the General Federation of Chambers of Commerce",
  FOR_about_title_4:
    "Member of the Navigation Division at the Alexandria Chamber of Commerce",
  FOR_about_title_5:
    'He holds a master\'s degree in "Business Administration and International Marketing" in 2019',
  FOR_about_hitham_text_1:
    "Haitham Talha is an Egyptian entrepreneur who graduated from the Faculty of Al-Alsun, Ain Shams University, Spanish Department",
  FOR_about_hitham_text_2:
    "He started working in the field of trade in 2003 in the field of international shipping, import and export In China and Egypt, he is also known in the media as Haitham in China, HIC, and he is the owner of a group of companies",
  FOR_about_hitham_text_3:
    "And the creator of a group of social media platforms under the same name with more than 2 million followers around the world, which provide content in the field of trade, import and export in general.",
  Haitham_certificates: "Haitham certificates",
  Like: "Like",
  subscribe: "subscribe",
  Follow: "Follow",
  Followers: "Followers",
  Haitham_Socials: "Haitham Socials",

  china_gate: "china gate",
  china: "china ",
  gate: " gate",
  Allyouneedtoknowaboutyourvisittochina:
    "All you need to know about your visit to china",
  showMore: " show More",

  showDetails: "Show details",
  chinaNews: "China News",
  moreWorldNews: "More World News",
  moreChinaNews: "More news about China",
  worldNews: "World News",
  buy: "Buy",
  sell: "Sell",
  all_news: "All News",
  event: "Events",
  topic: "Topics",
  MoreEvents: "More Events",
  MoreTopics: "More Topics",
  Featured: "Featured",
};
