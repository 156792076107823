import React from "react";
import "antd/dist/antd.css";
import { Card } from "antd";
import { useLocalization } from "../../../hooks/useLocalization";
import "./NewsCard.scss";

const truncate = (str, n) => {
  return str?.length > n ? str.substr(0, n - 1) + "..." : str;
};
const NewsCard = ({ title, image, handleClick = () => {}, className }) => {
  const { t } = useLocalization();

  title = truncate(title, 100);
  return (
    <div className={`news_card_wrapper ${!!className ? className : ""}`}>
      <Card
        hoverable
        className={"news_card"}
        cover={<img alt="news" src={image} />}
        onClick={handleClick}
      >
        <Card.Meta className={"title"} title={title} />
        <div className="action">
          <span className="read_more">{t.showDetails}</span>
        </div>
      </Card>
    </div>
  );
};

export default NewsCard;
