import { Breadcrumb, Col, Row, Button, Image } from "antd";
import { AiOutlineLine } from "react-icons/ai";
import { Link, withRouter, useLocation, useParams } from "react-router-dom";
import Footer from "../../public/components/Footer/Footer";
import BrandsSlider from "../../public/components/BrandsSlider/BrandsSlider";
import { FiArrowUpRight } from "react-icons/fi";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import en from "../../public/localization/en";
import ar from "../../public/localization/ar";
import whatsapp from "../../public/images/whats-app.svg";
import websiteLogo from "../../public/images/web.svg";
import ReactPlayer from "react-player";

import {
  FaFacebookF,
  FaYoutube,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaTiktok,
} from "react-icons/fa";
import styles from "../../styles/pages/hic_grop.module.scss";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import OwnModal from "../../public/components/OwnModal/OwnModal";
import ServiceBrandItem from "../../public/components/ServiceBrandItem/ServiceBrandItem";

import { BsChevronDoubleRight, BsChevronDoubleLeft } from "react-icons/bs";

const testImg = [
  {
    id: 6,
    image: `${process.env.REACT_APP_API_BASE_URL}/images/165856335746.jpg`,
    created_at: "2022-05-19T10:10:34.000000Z",
    updated_at: "2022-05-19T10:10:34.000000Z",
  },
  {
    id: 4,
    image: `${process.env.REACT_APP_API_BASE_URL}/images/165856335746.jpg`,
    created_at: "2022-05-19T10:09:53.000000Z",
    updated_at: "2022-05-19T10:09:53.000000Z",
  },
  {
    id: 3,
    image: `${process.env.REACT_APP_API_BASE_URL}/images/165856335746.jpg`,
    created_at: "2022-05-19T10:09:45.000000Z",
    updated_at: "2022-05-19T10:09:45.000000Z",
  },
  {
    id: 2,
    image: `${process.env.REACT_APP_API_BASE_URL}/images/165856335746.jpg`,
    created_at: "2022-05-13T16:55:02.000000Z",
    updated_at: "2022-05-19T10:09:22.000000Z",
  },
  {
    id: 1,
    image: `${process.env.REACT_APP_API_BASE_URL}/images/16529549740.jpg`,
    created_at: "2022-05-13T16:54:50.000000Z",
    updated_at: "2022-05-19T10:09:34.000000Z",
  },
];

const settingsGallary = {
  // dots: true,
  infinite: true,
  speed: 800,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  nextArrow: <BsChevronDoubleRight />,
  prevArrow: <BsChevronDoubleLeft />,
  // centerMode: true,

  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2.5,
      },
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 2.5,
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 1.5,
      },
    },
  ],
};

function SingleBrand() {
  const { currentLang } = useSelector((state) => state.user);
  const t = currentLang === "en" ? en : ar;
  const location = useLocation();
  const [brandData, setBrandData] = useState(null);
  const [brandes, setbrandes] = useState([]);
  const params = useParams();

  console.log(params);
  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
    document.getElementById("main-header").classList.add("active");
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/brand/${params?.id}`)
      .then((response) => response.json())
      .then((data) => setBrandData(data?.data?.brand[0]));

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/brands`)
      .then((response) => response.json())
      .then((data) => setbrandes(data?.data?.brand));
  }, [params?.id]);

  return (
    <>
      <div className={`hic_grop_page ${styles.hic_grop_page}`}>
        <Helmet>
          <title>
            {currentLang === "en" ? brandData?.title_en : brandData?.title_ar}
          </title>
          <meta
            name="title"
            content={
              currentLang === "en" ? brandData?.title_en : brandData?.title_ar
            }
          />
          <meta
            name="description"
            content={
              currentLang === "en"
                ? brandData?.description_en
                : brandData?.description_ar
            }
          />
          <meta
            property="og:title"
            content={
              currentLang === "en" ? brandData?.title_en : brandData?.title_ar
            }
          />
          <meta
            property="og:description"
            content={
              currentLang === "en"
                ? brandData?.description_en
                : brandData?.description_ar
            }
          />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
        <div className="container">
          <div className={styles.hic_grop_page_holder}>
            <div className={styles.hic_grop_page_content}>
              {/*  */}
              <section className="section-vh">
                <div
                  className={styles.image_hero}
                  style={{
                    backgroundImage: "url(" + brandData?.cover_image + ")",
                  }}
                  data-aos="zoom-in"
                ></div>
                {/* START COMPONENT */}
                <div
                  className={styles.text_social_component}
                  data-aos="fade-up"
                >
                  <div className={styles.box_style}>
                    <div className={styles.bg_color}></div>
                    <h2 className={`solid-txt ${styles.solid_text}`}>
                      {currentLang === "en"
                        ? brandData?.title_en
                        : brandData?.title_ar}
                    </h2>
                  </div>
                  {/* {console.log(brandData)} */}
                  <div className={styles.text_social_component_row}>
                    <div className={styles.img_holder}>
                      {brandData?.image && (
                        <Image
                          // src="/images/ASMA-MARINE.png"
                          src={brandData?.image}
                          alt="brand"
                          preview={false}
                        />
                      )}
                    </div>
                    <div className={styles.text_content}>
                      <h4>
                        {currentLang === "en"
                          ? brandData?.title_en
                          : brandData?.title_ar}
                      </h4>
                      <div>
                        <p>
                          {currentLang === "en"
                            ? brandData?.description_en
                            : brandData?.description_ar}
                        </p>
                      </div>
                    </div>
                    <div className={styles.social_holder}>
                      <ul>
                        {brandData?.facebook && (
                          <li>
                            <a
                              href={brandData?.facebook}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaFacebookF />
                            </a>
                          </li>
                        )}
                        {brandData?.youtube && (
                          <li>
                            <a
                              href={brandData?.youtube}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaYoutube />
                            </a>
                          </li>
                        )}
                        {brandData?.twitter && (
                          <li>
                            <a
                              href={brandData?.twitter}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaTwitter />
                            </a>
                          </li>
                        )}
                        {brandData?.instagram && (
                          <li>
                            <a
                              href={brandData?.instagram}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaInstagram />
                            </a>
                          </li>
                        )}
                        {brandData?.linkedin && (
                          <li>
                            <a
                              href={brandData?.linkedin}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaLinkedinIn />
                            </a>
                          </li>
                        )}
                        {brandData?.tiktok && (
                          <li>
                            <a
                              href={brandData?.tiktok}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaTiktok />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* END COMPONENT */}
              </section>

              {/* ############# Start edit ############# */}
              <section className={`${styles.section_vh}`}>
                <section className={styles.asma_marine_services}>
                  <div className={styles.content_holder}>
                    {currentLang === "en" ? (
                      <h2 className={styles.title} data-aos="fade-up">
                        {brandData?.title_en} {t.services}
                      </h2>
                    ) : (
                      <h2 className={styles.title} data-aos="fade-up">
                        {t.services} {brandData?.title_ar}
                      </h2>
                    )}
                    <div className={styles.content}>
                      <div className={styles.content_row}>
                        {brandData?.services?.map((service) => (
                          <ServiceBrandItem
                            service={service}
                            key={service.id}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </section>
              </section>

              {!!brandData?.gallery?.length && (
                <div
                  className="slider_holder slider-brands-gallary"
                  data-aos="zoom-in"
                >
                  <h2 className={styles.title_2} data-aos="fade-left">
                    {t.gallery}
                  </h2>
                  {/* <Image.PreviewGroup> */}
                  <Slider {...settingsGallary} className="slider-hero">
                    {brandData?.gallery?.map((s) => (
                      <div key={s?.id}>
                        <div className="slide_content_holder">
                          <div className="image_holder kaifa-slider-img-holder">
                            {s?.video ? (
                              <ReactPlayer
                                width="100%"
                                height="100%"
                                volume={0.5}
                                url={s?.video}
                              />
                            ) : (
                              <Image
                                src={s?.image}
                                preview={false}
                                className="kaifa-slider-img"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                  {/* </Image.PreviewGroup> */}
                </div>
              )}
              <BrandsSlider brands={brandes} />
              <section className="section-vh">
                <div className={styles.contact_hero}>
                  <div className={styles.bg_color} data-aos="fade-up"></div>
                  <Row justify="center" align="middle">
                    <Col>
                      <div
                        className={styles.contact_content}
                        data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <h2>
                          {t.Contact}{" "}
                          {currentLang === "en"
                            ? brandData?.title_en
                            : brandData?.title_ar}{" "}
                        </h2>
                        <div className={styles.box_btns}>
                          <Button
                            type="primary"
                            block
                            className={styles.btn_whatsApp}
                          >
                            <a
                              style={{
                                display: "flex",
                              }}
                              href={`https://api.whatsapp.com/send?phone=${brandData?.whatsapp_number}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={whatsapp}
                                width={36}
                                height={36}
                                alt="whatsapp"
                              />
                              <span>
                                {t.WhatsApp}
                                <FiArrowUpRight />
                              </span>
                            </a>
                          </Button>
                          {brandData?.website && (
                            <Button
                              type="primary"
                              block
                              className={styles.btn_asmsLink}
                            >
                              <a
                                style={{
                                  display: "flex",
                                }}
                                href={`${brandData?.website}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={websiteLogo}
                                  width={36}
                                  height={36}
                                  alt="website"
                                />
                                <span>
                                  {brandData?.website}
                                  <FiArrowUpRight />
                                </span>
                              </a>
                            </Button>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SingleBrand;
