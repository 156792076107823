function GoogleMap({ width, height, src }) {
  return (
    <div className="mapouter">
      <div className="gmap_canvas">
        <iframe
          width={width || "100%"}
          height={height || "270"}
          id="gmap_canvas"
          src={src}
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
        ></iframe>
      </div>
    </div>
  );
}

export default GoogleMap;
