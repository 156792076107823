import NewsCard from "../NewsCard/NewsCard";
import "./NewsSection.scss";
import { useLocalization } from "../../../hooks/useLocalization";
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
import Slider from "react-slick";
import React from "react";
import { Link, useHistory } from "react-router-dom";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <HiArrowNarrowRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <HiArrowNarrowLeft />
    </div>
  );
}

const NewsSection = ({ news, title, withSlider = true, type,autoplay }) => {
  const { t, currentLang } = useLocalization();
  const settings = {
    infinite: true,
    speed: 800,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: autoplay,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    centerMode: true,
    variableWidth: true,
    rtl: currentLang === "ar",
  };
  const history = useHistory();
  const navigateTo = (id) => {
    type === "world"
      ? history.push(`/world-news/${id}`)
      : history.push(`/china-news/${id}`);
  };

  if (!news?.length) return null;

  return (
    <section className="section-vh news-section" data-aos="fade-up">
      <div className="container">
        <div className="news-section--title_holder news-section--title">
          <h3> {title}</h3>
        </div>
        <div className={`apps_slider_holder `}>
          {withSlider ? (
            <Slider {...settings}>
              {React.Children.toArray(
                news?.map((data) => (
                  <NewsCard
                    title={
                      currentLang === "en" ? data?.title_en : data?.title_ar
                    }
                    image={data?.image}
                    handleClick={() => navigateTo(data.id)}
                  />
                ))
              )}
            </Slider>
          ) : (
            <div className="news-section--world">
              {news?.map((data) => (
                <NewsCard
                  title={currentLang === "en" ? data?.title_en : data?.title_ar}
                  image={data?.image}
                  className="news_card--mobile"
                  handleClick={() => history.push(`/world-news/${data.id}`)}
                />
              ))}
            </div>
          )}
        </div>
        {/* {!withSlider && ( */}
          <div className="go_link_holder">
            <Link to={`/${type}-news`} className={"go_link"}>
              <HiArrowNarrowLeft className={`icon icon_left`} />
              {t.all_news}
              <HiArrowNarrowRight className={`icon icon_right`} />
            </Link>
          </div>
        {/* )} */}
      </div>
    </section>
  );
};

export default NewsSection;
