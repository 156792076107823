import { Button, Col, Image, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import OwnModal from "../OwnModal/OwnModal";
import brand_logo from "../../images/img_5.jpeg";
import { useHistory } from "react-router-dom";

import styles from "./ServiceBrandItem.module.scss";

function ServiceBrandItem({ service }) {
  const history = useHistory();
  const { currentLang } = useSelector((state) => state.user);

  return (
    <>
      <div className={styles.card_holder} data-aos="flip-left">
        <div
          className={styles.card}
          title="Click for more details"
          onClick={() =>{
            history.push(`/service/${service.id}`)
          }}
        >
          <div className={`${styles.img_holder}  ${styles.red}`}>
            <img src={service?.image} alt="img" />
          </div>
          <div className={styles.text_holder}>
            <h2>
              {currentLang === "en" ? service?.title_en : service?.title_ar}
            </h2>
            {/* <p>
                                    {currentLang === "en"
                                      ? service?.description_en
                                      : service?.description_ar}
                                  </p> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceBrandItem;
