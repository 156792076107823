import React from "react";
import { Image } from "antd";
import styles from "./HicGroup.module.scss";
import { Link, withRouter } from "react-router-dom";
import BrandsSlider from "../../components/BrandsSlider/BrandsSlider";

function HicGroup({ brands, t }) {
  return (
    <section className="section-vhh">
      <div className="container">
      <div className={styles.hic_group_section}>
        <div className={styles.section_info_holder}>
          <div className={styles.section_content_holder} data-aos="fade-up">
            <div className={styles.info_left_conetnt}>
              <h3 className={styles.section_headline}>{t.his_group}</h3>
              <p className={styles.section_p}>{t.his_group_description}</p>
            </div>
            <div className="info_right_content">
              <h2 className="solid-txt">{t.his_group}</h2>
            </div>
          </div>
        </div>
        <BrandsSlider brands={brands}/>
      </div>
      </div>

    </section>
  );
}

export default HicGroup;
