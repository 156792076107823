import React, { useState, useEffect } from "react";
import {
  Link,
  withRouter,
  useLocation,
  useHistory,
  useParams,
} from "react-router-dom";
import { Menu, Dropdown, Image } from "antd";
import { AiOutlineBars } from "react-icons/ai";
import chevronDown from "../../images/chevron-down.svg";
import en from "../../localization/en";
import ar from "../../localization/ar";
import styles from "./Header.module.scss";
import hic_logo from "../../images/Logo.png";
import { Link as I18Link } from "react-router-i18n";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentLang } from "../../../redux/user/user.action";
import { animateScroll as scroll } from "react-scroll";

function Header(props) {
  const [showMenu, setShowMenu] = useState(false);
  const [brandes, setbrandes] = useState(null);
  const { currentLang } = useSelector((state) => state.user);
  const t = currentLang === "en" ? en : ar;
  const locationRoute = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  // console.log(history, "history")
  const { location } = history;
  const { pathname } = location;
  // const params = useParams();
  // console.log(params, "paramsparamsv")
  const chanageLang = (lang) => {
    dispatch(setCurrentLang(lang));
    document.getElementsByTagName("html")[0].setAttribute("lang", lang);
    // setTimeout(() => {
    //   window.location.reload()
    //  }, 200)
  };
  // console.log(props, "props.match.params")
  // console.log(props.match.params)
  // const stripLocale = (pathname, locale) => {
  //   if (!locale) {
  //     return pathname;
  //   }
  //   return pathname.replace(`/${locale}`, '');
  // };

  const handler = () => {
    const { pathname } = location;
    console.log(pathname);
    if (pathname === "/") {
      if (
        document.body.scrollTop > 300 ||
        document.documentElement.scrollTop > 300
      ) {
        document.getElementById("main-header").classList.add("active");
      } else {
        document.getElementById("main-header").classList.remove("active");
      }
    } else {
      console.log("elseelse");
      document.getElementById("main-header").classList.add("active");
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/brands`)
      .then((response) => response.json())
      .then((data) => setbrandes(data?.data?.brand));
    document.getElementById("main-header").classList.add("active");
  }, []);

  useEffect(() => {
    // const {pathname} = location;
    // document.getElementById("main-header").classList.add("active");
    //   if(pathname === "/"){
    //     window.addEventListener("scroll", handler);
    //     return () => window.removeEventListener("scroll", handler);
    //   }else{
    //     document.getElementById("main-header").classList.add("active");
    //   }
    // console.log(locationRoute, "locationRoute")
    setShowMenu(false);
  }, [locationRoute]);

  return (
    <header
      className={styles.main_header}
      data-aos="fade-down"
      id="main-header"
    >
      <div className={styles.container}>
        <div
          className={`${styles.header_content} ${
            showMenu ? styles.menu_active : undefined
          }`}
        >
          <div className={styles.brand}>
            <Link to="/">
              <Image src={hic_logo} alt="HIC logo" preview={false} />
            </Link>
          </div>
          <nav className={styles.nav}>
            <ul>
              <li>
                {/* <a onClick={() => {
                  if(pathname === "/"){
                   return scroll.scrollToTop()
                  }else{
                    history.push("/")
                  }
                }}>
                {t.home}
                </a> */}
                <Link to="/">{t.home}</Link>
              </li>
              <li>
                <Link to="/about">{t.about}</Link>
              </li>
              <li>
                <Dropdown
                  overlay={
                    <Menu>
                      {brandes?.map((brnad) => (
                        <Menu.Item key={brnad?.id}>
                          <Link
                            to={{
                              pathname: `/hic-group/${brnad?.id}`,
                            }}
                            rel="noopener noreferrer"
                          >
                            {brnad?.title_en}
                          </Link>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  arrow={{ pointAtCenter: true }}
                  trigger={["hover"]}
                >
                  <a className={`ant-dropdown-link ${styles.link_with_arrow} rtl-text`}>
                    {t.HICGroup}
                    <img src={chevronDown} width={16} alt="" />
                  </a>
                </Dropdown>
              </li>
              {/* <li>
                <Dropdown
                  overlay={hicGroupMenu}
                  arrow={{ pointAtCenter: true }}
                >
                  <a
                    className={`ant-dropdown-link ${styles.link_with_arrow}`}
                    onClick={(e) => e.preventDefault()}
                  >
                    {t.services} <Image src={chevronDown} width={16} alt="" />
                  </a>
                </Dropdown>
              </li> */}
              <li>
                <Link to="/media">{t.media}</Link>
              </li>
              <li>
                <Link to="/kaifa">{t.kaifa}</Link>
              </li>
              <li>
                <Link to="/china-gate">{t.china_gate}</Link>
              </li>
              <li>
                <Link to="/contact-us">{t.contactus}</Link>
              </li>
            </ul>
          </nav>
          <div className={styles.menu_lang_holder}>
            <button
              className={`btn ${styles.btn_lang} ${
                currentLang === "en" && styles.btn_lang_active
              }`}
              onClick={() => chanageLang("en")}
            >
              {/* <I18Link
                    className="lang-holder"
                    ignoreLocale
                    to={`/en${stripLocale(pathname, locale)}`}
                  > */}
              en
              {/* </I18Link> */}
            </button>
            <span className={styles.separator}></span>
            <button
              className={`btn ${styles.btn_lang} ${styles.btn_lang_ar} ${
                currentLang === "ar" && styles.btn_lang_active
              }`}
              onClick={() => chanageLang("ar")}
            >
              {/* <I18Link
                    className="lang-holder"
                    ignoreLocale
                    to={`/ar${stripLocale(pathname, locale)}`}
                  > */}
              ع{/* </I18Link> */}
            </button>

            <button
              className={`btn ${styles.toggle_menu}`}
              onClick={() => setShowMenu(!showMenu)}
            >
              <AiOutlineBars />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
