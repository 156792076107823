import { Breadcrumb, Col, Row, Button, Image } from "antd";
import { AiOutlineLine } from "react-icons/ai";
import { Link, withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import Footer from "../public/components/Footer/Footer";
import en from "../public/localization/en";
import ar from "../public/localization/ar";

import {
  FaFacebookF,
  FaYoutube,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaTiktok,
} from "react-icons/fa";

import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
import styles from "../styles/pages/kaifa.module.scss";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <HiArrowNarrowRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <HiArrowNarrowLeft />
    </div>
  );
}

const settings = {
  // dots: false,
  infinite: true,
  speed: 800,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  centerMode: true,

  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },

    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

function Kaifa() {
  const { currentLang } = useSelector((state) => state.user);
  const t = currentLang === "en" ? en : ar;
const [kaifaData, setKaifaData] = useState(null);
const [kaifaInNumbers, setKaifanNumbers] = useState([]);
const [kaifaImages, setkaifaImages] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/kaifa`)
      .then(response => response.json())
      .then(data => setKaifaData(data?.data?.courses[0]));

      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/kaifaInNumbers`)
      .then(response => response.json())
      .then(data => {
        setKaifanNumbers(data?.data?.kaifaInNumbers)
        setkaifaImages(data?.data?.kaifaImages)
      });


        const body = document.querySelector('#root');
        body.scrollIntoView({
          behavior: 'smooth'
        }, 500);
        document.getElementById("main-header").classList.add("active");
  }, [])

  return (
    <>
      <div className={`kaifa_page ${styles.kaifa_page}`}>
      <Helmet>
            <title>{currentLang === "en" ? kaifaData?.title_en : kaifaData?.title_ar}</title>
            <meta name="title" content={currentLang === "en" ? kaifaData?.title_en : kaifaData?.title_ar} />
            <meta name="description" content={currentLang === "en" ? kaifaData?.description_en : kaifaData?.description_ar} /> 
            <meta property="og:title" content={currentLang === "en" ? kaifaData?.title_en : kaifaData?.title_ar}  />
            <meta property="og:description" content={currentLang === "en" ? kaifaData?.description_en : kaifaData?.description_ar} />
            <meta property="og:url" content={window.location.href} />
        </Helmet>
        <div className="container">
          <div className={styles.kaifa_page_holder}>
            <Breadcrumb
              data-aos="fade-left"
              className={`breadcrumb ${styles.breadcrumb}`}
              separator={<AiOutlineLine />}
            >
              <Breadcrumb.Item className="breadcrumb-to">
                <Link to="/">{t.home}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-current">
                {t.kaifa}
              </Breadcrumb.Item>
            </Breadcrumb>
            <div
              className={`header_page_box ${styles.header_page_box}`}
              data-aos="zoom-in"
            >
              <h2>{t.kaifa}</h2>
            </div>
            <div className={styles.kaifa_page_content}>
              <div className={styles.image_hero}
              style={{
                backgroundImage: "url(" + kaifaData?.cover_image  + ")"
              }}
               data-aos="zoom-in"></div>

              {/* START COMPONENT */}
              <div
                className={styles.text_social_component_holder}
                data-aos="fade-up"
              >
                <div className={styles.text_social_component}>
                  <div className={styles.box_style}>
                    <div className={styles.bg_color}></div>
                    <h2 className={`solid-txt ${styles.solid_text}`}>
                    {currentLang === "en" ? kaifaData?.title_en : kaifaData?.title_ar}
                    </h2>
                  </div>

                  <Row gutter={[60, 50]} justify="center">
                    <Col xs={12} md={9} lg={6}>
                      <div className={styles.img_holder}>
                        <Image
                          src={kaifaData?.logo}
                          // style={{ width: "18.2rem" }}
                          preview={false}
                        />
                      </div>
                    </Col>
                    <Col xs={24} md={24} lg={12}>
                      <div className={styles.text_content}>
                        <h4>{currentLang === "en" ? kaifaData?.title_en : kaifaData?.title_ar}</h4>
                        <div>
                          <p>
                          {currentLang === "en" ? kaifaData?.description_en : kaifaData?.description_ar}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={9} lg={6}>
                      <div className={styles.social_holder}>
                      <ul>
                          {kaifaData?.facebook && 
                          <li>
                            <a href={kaifaData?.facebook} target="_blank" rel="noopener noreferrer">
                              <FaFacebookF />
                            </a>
                          </li>
                          }
                          {kaifaData?.youtube && 
                          <li>
                            <a href={kaifaData?.youtube} target="_blank" rel="noopener noreferrer">
                              <FaYoutube />
                            </a>
                          </li>
                          }
                          {kaifaData?.twitter && 
                          <li>
                            <a href={kaifaData?.twitter} target="_blank" rel="noopener noreferrer">
                            <FaTwitter />
                            </a>
                          </li>
                          }
                          {kaifaData?.instagram && 
                          <li>
                            <a href={kaifaData?.instagram} target="_blank" rel="noopener noreferrer">
                            <FaInstagram />
                            </a>
                          </li>
                          }
                          {kaifaData?.linkedin && 
                          <li>
                            <a href={kaifaData?.linkedin} target="_blank" rel="noopener noreferrer">
                            <FaLinkedinIn />
                            </a>
                          </li>
                          }
                          {kaifaData?.tiktok && 
                          <li>
                            <a href={kaifaData?.tiktok} target="_blank" rel="noopener noreferrer">
                            <FaTiktok />
                            </a>
                          </li>
                          }
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              {/* END COMPONENT */}

              <section className={`section-vh ${styles.section_vh}`}>
                <section className={styles.asma_marine_services}>
                  <div className={styles.content_holder}>
                    <h2 className={styles.title} data-aos="fade-left">
                      {t.KAIFANumbers}
                    </h2>
                    <div className={styles.content}>
                      <Row gutter={[50, 40]}>
                      {kaifaInNumbers?.map(service => 
                        <Col key={service?.id} xs={24} md={12} xl={8}>
                          <div
                            className={styles.card_holder}
                            data-aos="fade-up"
                          >
                            <div className={styles.card}>
                              <div
                                className={`${styles.img_holder}  ${styles.red}`}
                              >
                                <Image src={service?.image} preview={false} />
                              </div>
                              <div className={styles.text_holder}>
                                <h2>{currentLang === "en" ? service?.title_en : service?.title_ar}</h2>
                                <p>
                                {currentLang === "en" ? service?.description_en : service?.description_ar}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        )}
{/* 
                        <Col xs={24} md={12} xl={8}>
                          <div
                            className={styles.card_holder}
                            data-aos="fade-up"
                          >
                            <div className={styles.card}>
                              <div
                                className={`${styles.img_holder} ${styles.brown}`}
                              >
                                <Image src={img_2} preview={false} />
                              </div>
                              <div className={styles.text_holder}>
                                <h2>Place your title here</h2>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Scelerisque vitae nascetur
                                  lacus id viverra morbi. Euismod venenatis et
                                  venenatis tellus, suscipit. Donec euismod sed
                                  enim.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col xs={24} md={12} xl={8}>
                          <div
                            className={styles.card_holder}
                            data-aos="fade-up"
                          >
                            <div className={styles.card}>
                              <div
                                className={`${styles.img_holder} ${styles.yellow}`}
                              >
                                <Image src={img_2} preview={false} />
                              </div>
                              <div className={styles.text_holder}>
                                <h2>Place your title here</h2>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Scelerisque vitae nascetur
                                  lacus id viverra morbi. Euismod venenatis et
                                  venenatis tellus, suscipit. Donec euismod sed
                                  enim.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col> */}
                      </Row>
                    </div>
                  </div>
                </section>
              </section>
              <div className="slider_holder" data-aos="zoom-in">
              <h2 className={styles.title_2} data-aos="fade-left">
                      {t.gallery}
                    </h2>
                <Slider {...settings} className="slider-hero">
                  {kaifaImages?.map(s => 
                  <div key={s?.id}>
                    <div className="slide_content_holder">
                      <div className="image_holder kaifa-slider-img-holder">
                        <Image src={s?.image} preview={false} className="kaifa-slider-img" />
                      </div>
                    </div>
                  </div>
                    )}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Kaifa;
