import { Button, Col, Image, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Footer from "../../public/components/Footer/Footer";
import PlacesWrapper from "../../public/components/PlacesWrapper/PlacesWrapper";
import Slider from "react-slick";
import {
  BsChevronDoubleRight,
  BsChevronDoubleLeft,
  BsChevronDoubleDown,
} from "react-icons/bs";

import ph_1 from "../../public/images/ph-1.jpg";
import ph_2 from "../../public/images/ph-2.jpg";
import ph_3 from "../../public/images/ph-3.jpg";
import ph_4 from "../../public/images/ph-4.jpg";

import styles from "./ChinaGateDetailsService.module.scss";
import SinglePlace from "../../public/components/SinglePlace/SinglePlace";
import ServicePreview from "../../public/components/ServicePreview/ServicePreview";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import en from "../../public/localization/en";
import ar from "../../public/localization/ar";
import OwnModal from "../../public/components/OwnModal/OwnModal";
const settings = {
  // dots: true,
  infinite: true,
  speed: 800,
  slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  nextArrow: <BsChevronDoubleRight />,
  prevArrow: <BsChevronDoubleLeft />,
  // centerMode: true,

  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3.5,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};
function ChinaGateDetailsService() {
  const placesWrapper = useRef();
  const placesWrapper2 = useRef();
  const [unitData, setUnitData] = useState(null);
  const params = useParams();
  const { currentLang } = useSelector((state) => state.user);
  const t = currentLang === "en" ? en : ar;
  const [categoryData, setCategoryData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/china-gate/state-category/${params?.id}`)
      .then((response) => response.json())
      .then((data) => {
        setCategoryData(data?.data)
        setUnitData(data?.data?.state_category?.units[0] || null)
      });

      const body = document.querySelector("#root");
      body.scrollIntoView(
        {
          behavior: "smooth",
        },
        500
      );

  }, [params?.id]);

  const handeSetActiveunit = (unit) => {
    console.log(unit)
    // const body = document.querySelector("#root");
    // body.scrollIntoView(
    //   {
    //     behavior: "smooth",
    //   },
    //   500
    // );
    setUnitData(unit);
    setIsModalVisible(true); 
  }
  
  return (
    <>
      <div className={styles.china_gate_details_page}>
        <div className={styles.china_gate_details_inner} 
                 style={{
              backgroundImage: `url(${categoryData?.state_category?.cover_image})`,
            }}
            >
          <div
            className={styles.china_gate_details_header}
            // style={{
            //   backgroundImage: `linear-gradient(
            //   to top right,
            //   rgba(102, 18, 18, 0.6),
            //   rgba(0, 0, 0, 0.05)
            // ),url(${categoryData?.state_category?.cover_image})`,
            // }}
          >
            <div className={styles.side_by_side}>
              <Row gutter={20}>
                <Col xs={24} lg={12} xl={14}>
                  <div className={styles.text_wrapper}>
                    <div className="china_gate_title">
                      <span>{t.china}</span>
                      <span className="bold">{t.gate}</span>
                    </div>
                    <div className="place_descraption_style">
                      <div className="head">
                        <span className="color upper">{currentLang === "en" ? categoryData?.state_category?.title_en : 
                        categoryData?.state_category?.title_ar}</span>
                        {/* <span className="color upper">{categoryData?.state_category?.title_en}</span>
                        <span>{categoryData?.state_category?.title_ar}</span> */}
                      </div>
                      <div className="desc">
                      {currentLang === "en" ? categoryData?.state_category?.description_en : categoryData?.state_category?.description_ar}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={24} lg={12} xl={10}>
                  {unitData &&
                  <ServicePreview data={unitData} />
                  }
                </Col>
              </Row>
            </div>
            <div className={styles.slider_wrapper}>
              <Slider {...settings} className="slider-wrabber containerz state-slider-wrapper">
                {/* START */}
                {categoryData?.state_category && categoryData?.state_category?.units?.slice(0,6)?.map(s => 
                  <div className={styles.slide_content_holder} key={s?.id}>
                    <SinglePlace
                      style={{ height: "20rem", marginTop : 20 }}
                      service
                      img={s?.icon_image}
                      title={{ 
                        ar: currentLang === "en" ? s?.title_en : s?.title_ar,
                      }}
                      onClick={() => setUnitData(s)}
                      // title={{ ar: currentLang === "en" ? s?.title_en : s?.title_ar , en: "" }}

                      />
                  </div>
                )}
                  {/* <div className={styles.slide_content_holder}>
                    <SinglePlace
                      style={{
                        height: "14rem",
                        // width: "14rem"
                      }}
                      img={ph_1}
                      service
                      title={{ ar: "بلدية بكين", en: "future school" }}
                      onClick={() => setPerviewImg(ph_1)}
                    />
                  </div> */}
                {/* START */}
              </Slider>
            </div>
            {categoryData?.state_category?.units?.length > 6 &&
            <Button
              type="link"
              className={styles.btn_show_more}
              style={{ marginTop: 60 }}
              onClick={() =>
                placesWrapper.current.scrollIntoView({
                  behavior: "smooth",
                })
              }
            >
              {t.showMore}
              <span>
                <BsChevronDoubleDown />
              </span>
            </Button>}
          </div>
              {categoryData?.state_category?.units?.length > 6 &&
          <section className={styles.places_wrapper_holder} ref={placesWrapper}> 
            <PlacesWrapper service isUnit handeSetActiveunit={(s) => handeSetActiveunit(s)}  list={categoryData?.state_category?.units?.slice(6, 66) || []} />
          </section>
              }

          <OwnModal  onCancel={() => setIsModalVisible(false)} model_unit isModalVisible={isModalVisible} closable={true} width={"70%"}>
          <div className={`${styles.side_by_side}  unit-model-body`} 
          style={{
            backgroundImage: `linear-gradient(
            to top right,
            rgba(102, 18, 18, 0.6),
            rgba(0, 0, 0, 0.05)
          ),url(${categoryData?.state_category?.cover_image})`,
          }}
          >
              <Row gutter={20}>
                <Col xs={24} lg={12} xl={12}>
                  <div className={styles.text_wrapper}> 
                    {/* <div className="china_gate_title">
                      <span>{t.china}</span>
                      <span className="bold">{t.gate}</span>
                    </div> */}
                    <div className="place_descraption_style">
                      <div className="head">
                        <span className="color upper">
                        {currentLang === "en" ? unitData?.title_en : unitData?.title_ar}

                        </span>
                        {/* <span className="color upper">{categoryData?.state_category?.title_en}</span>
                        <span>{categoryData?.state_category?.title_ar}</span> */}
                      </div>
                      <div className="desc">
                      {currentLang === "en" ? unitData?.description_en : unitData?.description_ar}
                      </div>
                    </div> 
                  </div>
                </Col>
                <Col xs={24} lg={12} xl={12}>
                  {unitData &&
                  <ServicePreview hideInfo data={unitData} />
                  }
                </Col>
              </Row>
            </div>
          </OwnModal>

        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default ChinaGateDetailsService;
