import { Breadcrumb, Col, Row } from "antd";
import styles from "../styles/pages/ContactUs.module.scss";
import { Link, withRouter } from "react-router-dom";
import { AiOutlineLine } from "react-icons/ai";
import { FiArrowUpRight } from "react-icons/fi";
import { RiArrowRightUpLine } from "react-icons/ri";
import GoogleMap from "../public/components/GoogleMap/GoogleMap";
import FollowUs from "../public/components/FollowUs/FollowUs";
import {
  FaFacebook,
  FaTwitter,
  FaYoutube,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";

import en from "../public/localization/en";
import ar from "../public/localization/ar";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function ContactUs() {
  const { currentLang } = useSelector((state) => state.user);
  const t = currentLang === "en" ? en : ar;

  useEffect(() => {
    const body = document.querySelector('#root');
    body.scrollIntoView({
      behavior: 'smooth'
    }, 500);
    document.getElementById("main-header").classList.add("active");
  }, []);

  return (
    <div className={styles.contact_us_wapper}>
        <Helmet>
            <title>{t.contactus}</title>
            <meta name="title" content={t.contactus} />
            <meta property="og:title" content={t.contactus}  />
            <meta property="og:url" content={window.location.href} />
        </Helmet>
      <div className={` ${styles.container}`}>
        <Breadcrumb
          data-aos="fade-right"
          className={`breadcrumb ${styles.breadcrumb}`}
          separator={<AiOutlineLine />}
        >
          <Breadcrumb.Item className="breadcrumb-to">
            <Link to="/">
              <a>{t.home}</a>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-current">
            {t.contactus}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className={styles.locations_wrapper}>
          <Row gutter={[30, 40]}>
            <Col xs={24} md={12} xl={8}>
              <div className={styles.location_holder} data-aos="fade-top">
                <ul className={styles.location}>
                  <li className={styles.map}>
                    <GoogleMap src="https://maps.google.com/maps?q=ASMA+MARINE+Global+Logistics+and+Freight+Forwarder&t=&z=15&ie=UTF8&iwloc=&output=embed" />
                  </li>
                  <li className={styles.title}>{t.egyptCairo}</li>
                  <li className={styles.description}>
                    <ul>
                      <li>
                        {t.working_hours}: {t.FOR_working_hours}
                      </li>
                      <li>
                        {t.tel}: {t.FOR_egypt_tel}
                      </li>
                      <li>
                        {t.email}: {t.FOR_email}
                      </li>
                      <li>
                        {t.address}: {t.FOR_egypt_address}
                      </li>
                    </ul>
                  </li>
                  <li className={styles.link_location}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.google.com/maps/search/ASMA+MARINE+Global+Logistics+and+Freight+Forwarder/@30.1021432,31.3808091,17z?hl=en-EG"
                    >
                      <a>
                        {t.location_on_map}
                        <FiArrowUpRight />
                      </a>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <div className={styles.location_holder} data-aos="fade-down">
                <ul className={styles.location}>
                  <li className={styles.map}>
                    <GoogleMap src="https://maps.google.com/maps?q=Fuli+Int'l.+Shoes+Industry+Bus.+Ctr,+%E5%8C%97%E4%BA%AC%E8%B7%AF%E5%95%86%E4%B8%9A%E5%8C%BA+Li+Wan+Qu,+Guang+Zhou+Shi,+Guang+Dong+Sheng,+China&t=&z=17&ie=UTF8&iwloc=&output=embed" />
                  </li>
                  <li className={styles.title}>{t.chinaGuangzhou}</li>
                  <li className={styles.description}>
                    <ul>
                      {t.working_hours}: {t.FOR_working_hours}
                      <li>
                        {t.tel}: {t.FOR_whats_app}
                      </li>
                      <li>
                        {t.email}: {t.FOR_email}
                      </li>
                      <li>
                        {t.address}: {t.FOR_china_guangzhou_address}
                      </li>
                    </ul>
                  </li>
                  <li className={styles.link_location}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.google.com/maps/search/Fuli+Int'l.+Shoes+Industry+Bus.+Ctr,+北京路商业区+Li+Wan+Qu,+Guang+Zhou+Shi,+Guang+Dong+Sheng,+China/@23.1420555,113.2407303,17z?hl=en-EG"
                    >
                      <a>
                        {t.location_on_map}
                        <FiArrowUpRight />
                      </a>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <div className={styles.location_holder} data-aos="fade-top">
                <ul className={styles.location}>
                  <li className={styles.map}>
                    <GoogleMap src="https://maps.google.com/maps?q=International%20Trade%20Building,%20Yiwu,%20Jinhua,%20Zhejiang,%20China&t=&z=19&ie=UTF8&iwloc=&output=embed" />
                  </li>
                  <li className={styles.title}>{t.chinaYiwu}</li>
                  <li className={styles.description}>
                    <ul>
                      <ul>
                        {t.working_hours}: {t.FOR_working_hours}
                        <li>
                          {t.tel}: {t.FOR_whats_app}
                        </li>
                        <li>
                          {t.email}: {t.FOR_email}
                        </li>
                        <li>
                          {t.address}: {t.FOR_china_yiwu_address}
                        </li>
                      </ul>
                    </ul>
                  </li>
                  <li className={styles.link_location}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.google.com/maps/search/International+Trade+Building,+No.+999+Chouzhou+North+road,+Futian+Street,+Yiwu,+Zhejiang,+China/@29.3336655,120.1013464,17z?hl=en-EG"
                    >
                      <a>
                        {t.location_on_map}
                        <FiArrowUpRight />
                      </a>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>

        <FollowUs showFind t={t} />

        <footer className={styles.footer}>
          <p>{t.rightsreserved}</p>
        </footer>
      </div>
    </div>
  );
}

export default ContactUs;
