import { Breadcrumb, Tabs } from "antd";
import { AiOutlineLine } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import styles from "../../styles/pages/Media.module.scss";
import TabHead from "../../public/components/TabHead/TabHead";
import { useState, useEffect } from "react";
import LiveStreamingTab from "../../public/components/MediaTabs/LiveStreamingTab/LiveStreamingTab";
import Footer from "../../public/components/Footer/Footer";
import en from "../../public/localization/en";
import ar from "../../public/localization/ar";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const toMediaCategoryShape = ({ id, title_en, title_ar, media = [] }) => ({
  id,
  title_en,
  title_ar,
  media: media.map((m) => ({ ...m, category_media_id: id })),
  created_at: "2022-06-20T11:39:29.000000Z",
  updated_at: "2022-06-20T11:39:29.000000Z",
});
function MediaPage() {
  const { currentLang } = useSelector((state) => state.user);
  const t = currentLang === "en" ? en : ar;
  const history = useHistory();
  const [activeTabKey, setActiveTabKey] = useState(
    history.location.state?.activeTabKey || "1"
  );
  console.log(history.location.state?.activeTabKey);
  const [media_category, setMedia] = useState([]);
  useEffect(() => {
    // console.log(router?.query)
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/media`)
      .then((response) => response.json())
      .then((data) => {
        const {
          media_category = [],
          events = [],
          worldInformation = [],
        } = data?.data;

        setMedia([
          ...media_category,
          toMediaCategoryShape({
            id: "events",
            title_en: "Events",
            title_ar: "فعاليات",
            media: events,
          }),
          toMediaCategoryShape({
            id: "world_information",
            title_en: "Around the world",
            title_ar: "حول العالم",
            media: worldInformation,
          }),
        ]);

        setActiveTabKey(
          history.location.state?.activeTabKey ||
            "" + data?.data?.media_category[0]?.id
        );
      });
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
    document.getElementById("main-header").classList.add("active");
  }, []);

  const categoryCountTitle = {
    7: t.article,
    events: t.event,
    world_information: t.topic,
  };

  return (
    <>
      <div className={styles.media_page}>
        <Helmet>
          <title>{t.media}</title>
          <meta name="title" content={t.media} />
          <meta property="og:title" content={t.media} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
        <div className="container">
          <div className={styles.media_page_holder}>
            <div
              className={`header_page_box ${styles.header_page_box}`}
              data-aos="zoom-in"
            >
              <h2>{t.media}</h2>
            </div>
            <div className={`media_page_content ${styles.media_page_content}`}>
              <div className="tabs_holder">
                {/* START TABS */}
                <Tabs
                  activeKey={activeTabKey}
                  onChange={(tabKey) => setActiveTabKey(tabKey)}
                >
                  {/* TAB */}
                  {media_category?.map((cat) => (
                    <Tabs.TabPane
                      key={"" + cat?.id}
                      tab={
                        <TabHead
                          title={
                            currentLang === "en" ? cat?.title_en : cat?.title_ar
                          }
                          count={
                            cat?.media?.length +
                            "   " +
                            (categoryCountTitle[cat?.id] || t.vedio)
                          }
                          active={activeTabKey === cat?.id}
                        />
                      }
                    >
                      <LiveStreamingTab media={cat} />
                    </Tabs.TabPane>
                  ))}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MediaPage;
