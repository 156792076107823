import { Col, Row } from "antd";
import React from "react";
import SinglePlace from "../SinglePlace/SinglePlace";
import ph_1 from "../../images/ph-1.jpg";
import ph_2 from "../../images/ph-2.jpg";
import ph_3 from "../../images/ph-3.jpg";
import ph_4 from "../../images/ph-4.jpg";

import styles from "./PlacesWrapper.module.scss";
import { useSelector } from "react-redux";
import en from "../../localization/en";
import ar from "../../localization/ar";

function PlacesWrapper({
  list = [],
  service,
  onClick,
  isUnit,
  handeSetActiveunit,
}) {
  const { currentLang } = useSelector((state) => state.user);
  const t = currentLang === "en" ? en : ar;
  return (
    <section className={styles.places_wrapper}>
      <div className="container">
        <div className={styles.places_inner}>
          <div className={styles.places_List}>
            <div className={styles.grid}>
              {list?.map((s) => {
                return (
                  <SinglePlace
                    id={s?.id}
                    key={s?.id}
                    service={service}
                    handeSetActiveunit={handeSetActiveunit}
                    unitData={s}
                    isUnit={isUnit}
                    link
                    img={s?.icon_image || s?.cover_image}
                    title={{
                      ar: currentLang === "en" ? s?.title_en : s?.title_ar,
                    }}
                  />
                );
              })}
              {/* 
<>
              <SinglePlace
                link
                img={ph_1}
                title={{ ar: "بلدية بكين", en: "future school" }}
              />
              <SinglePlace
                link
                img={ph_2}
                title={{
                  ar: "مفعل شنغهاي",
                  en: "Gasoline cooperation",
                }}
              />
              <SinglePlace
                link
                img={ph_3}
                title={{
                  ar: "مستشفى ماو سيتونج",
                  en: "Loyalty and Hope Hospital",
                }}
              />
              <SinglePlace
                link
                img={ph_4}
                title={{
                  ar: "صرافة شاو بونج ",
                  en: "Loyalty and Hope Hospital",
                }}
              />
              <SinglePlace
                link
                img={ph_1}
                title={{ ar: "بلدية بكين", en: "future school" }}
              />
              <SinglePlace
                link
                img={ph_2}
                title={{
                  ar: "مفعل شنغهاي",
                  en: "Gasoline cooperation",
                }}
              />
              <SinglePlace
                link
                img={ph_3}
                title={{
                  ar: "مستشفى ماو سيتونج",
                  en: "Loyalty and Hope Hospital",
                }}
              />
              <SinglePlace
                link
                img={ph_4}
                title={{
                  ar: "صرافة شاو بونج ",
                  en: "Loyalty and Hope Hospital",
                }}
              />
              <SinglePlace
                link
                img={ph_1}
                title={{ ar: "بلدية بكين", en: "future school" }}
              />
              <SinglePlace
                link
                img={ph_2}
                title={{
                  ar: "مفعل شنغهاي",
                  en: "Gasoline cooperation",
                }}
              />
</> */}
            </div>
            {/* <Row gutter={[50, 50]} justify="center" align="top">
              <Col flex={"auto"}>
                <SinglePlace
                  link
                  img={ph_1}
                  title={{ ar: "بلدية بكين", en: "future school" }}
                  small
                />
              </Col>
              <Col flex={"auto"}>
                <SinglePlace
                  link
                  img={ph_2}
                  title={{
                    ar: "مفعل شنغهاي",
                    en: "Gasoline cooperation",
                  }}
                />
              </Col>
              <Col flex={"auto"}>
                <SinglePlace
                  link
                  img={ph_3}
                  title={{
                    ar: "مستشفى ماو سيتونج",
                    en: "Loyalty and Hope Hospital",
                  }}
                  small
                />
              </Col>
              <Col flex={"auto"}>
                <SinglePlace
                  link
                  img={ph_4}
                  title={{ ar: "بلدية بكين", en: "future school" }}
                />
              </Col>
              <Col flex={"auto"}>
                <SinglePlace
                  link
                  img={ph_1}
                  title={{ ar: "بلدية بكين", en: "future school" }}
                  small
                />
              </Col>
              <Col flex={"auto"}>
                <SinglePlace
                  link
                  img={ph_2}
                  title={{
                    ar: "مفعل شنغهاي",
                    en: "Gasoline cooperation",
                  }}
                />
              </Col>
              <Col flex={"auto"}>
                <SinglePlace
                  link
                  img={ph_3}
                  title={{
                    ar: "مستشفى ماو سيتونج",
                    en: "Loyalty and Hope Hospital",
                  }}
                  small
                />
              </Col>
              <Col flex={"auto"}>
                <SinglePlace
                  link
                  img={ph_4}
                  title={{ ar: "بلدية بكين", en: "future school" }}
                />
              </Col>
              <Col flex={"auto"}>
                <SinglePlace
                  link
                  img={ph_1}
                  title={{ ar: "بلدية بكين", en: "future school" }}
                  small
                />
              </Col>
              <Col flex={"auto"}>
                <SinglePlace
                  link
                  img={ph_2}
                  title={{
                    ar: "مفعل شنغهاي",
                    en: "Gasoline cooperation",
                  }}
                />
              </Col>
            </Row> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PlacesWrapper;
