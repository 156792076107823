import { Button, Form, Input, Image, message } from "antd";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useState } from "react";
import styles from "./NewsLetter.module.scss";
import close from "../../../public/images/close-circle.png";
import sub_image from "../../../public/images/sub_image.svg";
import axios from "axios";

function NewsLetter({ t }) {
  const [isVisible, setIsVisible] = useState(true);
  const [registerLoading, setloginLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setloginLoading(true);
    let body = new FormData();
    body.append("email", values?.email);

    axios({
      method: "post",
      Accept: "application/json",
      url: `${process.env.REACT_APP_API_BASE_URL}/api/newSubscriber`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: body,
    })
      .then(function (response) {
        setloginLoading(false);
        if (response?.data?.success) {
          message.success("subscribed successfully");
          form.resetFields();
        }
      })
      .catch(function (error) {
        setloginLoading(false);
        // message.error(error?.response?.data?.errors?.phone[0]);
      });
  };

  return (
    <div
      className={`${styles.news_letter_wrapper} ${
        isVisible ? "" : styles.hidden
      }`}
    >
      <div className={styles.news_letter_Holder}>
        <div className={styles.news_letter_content}>
          <div className={styles.header}>
            <div>
              <img src={sub_image} width={45} height={50} alt="news letter" />
            </div>
            <h2>{t.subscribe_to_our_newsletter}</h2>
          </div>
          <div className={styles.form_holder}>
            <Form
             form={form}
              name="basic"
              onFinish={onFinish}
            >
              <Form.Item
                name="email"
                rules={[{ type: "email" }]}
                className={styles.form_item}
              >
                <Input
                  placeholder={t.enter_your_email_address}
                  className={styles.form_input}
                />
              </Form.Item>

              <Form.Item className={styles.form_item_btn}>
                <Button
                  type="link"
                  htmlType="submit"
                  className={styles.btn_submit}
                  loading={registerLoading}
                >
                  {t.subscribe}
                  <HiArrowNarrowRight />
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <Button
          type="link"
          className={styles.btn_close}
          onClick={() => setIsVisible(false)}
        >
          <img src={close} width={18} height={18} alt="close" />
        </Button>
        <div className={styles.bg_color}></div>
      </div>
    </div>
  );
}

export default NewsLetter;
