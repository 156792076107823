import { Button, Col, Image, Row, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Footer from "../../public/components/Footer/Footer";
import PlacesWrapper from "../../public/components/PlacesWrapper/PlacesWrapper";
import Slider from "react-slick";
import { BsChevronDoubleRight, BsChevronDoubleLeft } from "react-icons/bs";
import { FaUsers, FaGlobeAsia } from "react-icons/fa";
import { BsChevronDoubleDown } from "react-icons/bs";
import ph_1 from "../../public/images/ph-1.jpg";
import ph_2 from "../../public/images/ph-2.jpg";
import ph_3 from "../../public/images/ph-3.jpg";
import ph_4 from "../../public/images/ph-4.jpg";
import { useSelector } from "react-redux";

import styles from "./ChinaGateDetails.module.scss";
import SinglePlace from "../../public/components/SinglePlace/SinglePlace";
import { useParams } from "react-router-dom";
import en from "../../public/localization/en";
import ar from "../../public/localization/ar";
const settings = {
  // dots: true,
  infinite: true,
  speed: 800,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  nextArrow: <BsChevronDoubleRight />,
  prevArrow: <BsChevronDoubleLeft />,
  // centerMode: true,

  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 2.5,
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 1.5,
      },
    },
  ],
};
function ChinaGateDetails() {
  const placesWrapper = useRef();
  const placesWrapper2 = useRef();
  const params = useParams();
  const [stateData, setStateData] = useState(null);
  const { currentLang } = useSelector((state) => state.user);
  const t = currentLang === "en" ? en : ar;

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/china-gate/state/${params?.id}`)
      .then((response) => response.json())
      .then((data) => setStateData(data?.data));

      const body = document.querySelector("#root");
      body.scrollIntoView(
        {
          behavior: "smooth",
        },
        500
      );

  }, [params?.id]);

  return (
    <>
      <div className={styles.china_gate_details_page}>
        <div className={styles.china_gate_details_inner}
        
        style={{
          backgroundImage: `linear-gradient(
               to top right,
               rgba(102, 18, 18, 0.6),
               rgba(0, 0, 0, 0.05)
             ),
            url(${stateData?.state?.cover_image})`,
        }}
        >
          <div
            className={styles.china_gate_details_header}
            // style={{
            //   backgroundImage: `linear-gradient(
            //   to top right,
            //   rgba(102, 18, 18, 0.6),
            //   rgba(0, 0, 0, 0.05)
            // ),url(${stateData?.state?.cover_image})`,
            // }}
          >
            <div className={styles.side_by_side}>
              <Row gutter={[20, 20]} justify="center" align="top">
                <Col xs={24} lg={15}>
                  <div className={styles.text_wrapper}>
                    <div className="china_gate_title">
                      <span>{t.china}</span>
                      <span className="bold">{t.gate}</span>
                    </div>
                    <div className="place_descraption_style">
                      <div className="head">
                      <span className="color upper">{currentLang === "en" ? stateData?.state?.title_en : 
                        stateData?.state?.title_ar}</span>
                        {/* <span className="color upper">{stateData?.state?.title_en}</span>
                        <span>{stateData?.state?.title_ar}</span> */}
                      </div> 
                      <div className="desc">
                        {currentLang === "en" ? stateData?.state?.description_en : stateData?.state?.description_ar}
                      </div>
                    </div>
                    <div className={styles.tooltip_info_wrapper}>
                    {currentLang === "en" ? 
                      <div className={styles.tooltip_info_inner}>
                      {stateData?.state?.icons?.map(icon => 
                        <Tooltip
                          className={styles.tooltip_hero} 
                          title={
                            <>
                              <h4
                                style={{
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: "#4465a3",
                                  textTransform: "capitalize",
                                }}
                              >
                                {/* {currentLang === "en" ? icon?.description_en : icon?.description_ar } */}
                              </h4>
                              <span
                                style={{
                                  fontSize: 14,
                                  color: "#111",
                                  lineHeight: 1.4,
                                }}
                              >
                               {currentLang === "en" ? icon?.description_en : icon?.description_ar }
                              </span>
                            </>
                          }
                          // placement={ currentLang === "en" ?  "bottomRight" : "bottomLeft" }
                          placement="bottomLeft"
                          arrowPointAtCenter
                          overlayInnerStyle={{
                            backgroundColor: "#ffffffaf",
                            borderRadius: 10,
                            width: "42rem",
                            padding: 10,
                          }}
                        >
                          <img src={icon?.image} width={40} height={40} style={{maxWidth : 40}} alt="" />
                        </Tooltip>
                          )}
                      </div> : 
                                            <div className={styles.tooltip_info_inner}>
                                            {stateData?.state?.icons?.map(icon => 
                                              <Tooltip
                                                className={styles.tooltip_hero}
                                                title={
                                                  <>
                                                    <h4
                                                      style={{
                                                        fontSize: 16,
                                                        fontWeight: 600,
                                                        color: "#4465a3",
                                                        textTransform: "capitalize",
                                                      }}
                                                    >
                                                      {/* {currentLang === "en" ? icon?.description_en : icon?.description_ar } */}
                                                    </h4>
                                                    <span
                                                      style={{
                                                        fontSize: 14,
                                                        color: "#111",
                                                        lineHeight: 1.4,
                                                      }}
                                                    >
                                                     {currentLang === "en" ? icon?.description_en : icon?.description_ar }
                                                    </span>
                                                  </>
                                                }
                                                // placement={ currentLang === "en" ?  "bottomRight" : "bottomLeft" }
                                                placement="bottomRight"
                                                arrowPointAtCenter
                                                overlayInnerStyle={{
                                                  backgroundColor: "#ffffffaf",
                                                  borderRadius: 10,
                                                  width: "42rem",
                                                  padding: 10,
                                                }}
                                              >
                                                <img src={icon?.image} width={40} height={40} style={{maxWidth : 40}} alt="" />
                                              </Tooltip>
                                                )}
                                            </div>
                      }
                    </div>
                  </div>
                </Col>
                <Col xs={24} lg={9}>
                  {stateData?.state?.ads[0] && 
                  <a href={stateData?.state?.ads[0] && stateData?.state?.ads[0]?.link} target="_blank" rel="noopener noreferrer" className="ADS-place" style={{ marginTop: "2rem" }}>
                     <img src={stateData?.state?.ads[0] && stateData?.state?.ads[0]?.image} alt="" /> 
                  </a>
                  }
                </Col>
              </Row>
            </div>
            <div className={styles.slider_wrapper}>
              <Slider {...settings} className="slider-wrabber containerz state-slider-wrapper">
                {/* START */}
                {stateData?.state_categories && stateData?.state_categories?.map(s => 
                  <div className={styles.slide_content_holder} key={s?.id}>
                    <SinglePlace
                      style={{ height: "20rem", marginTop : 20 }}
                      service
                      link
                      id={s?.id}
                      img={s?.cover_image}
                      title={{ 
                        ar: currentLang === "en" ? s?.title_en : s?.title_ar,
                      }}
                      // title={{ ar: currentLang === "en" ? s?.title_en : s?.title_ar , en: "" }}

                      />
                  </div>
                )}
              </Slider>
            </div>
            {/* {stateData?.state_categories?.length > 6 &&
            <Button
            type="link"
            className={styles.btn_show_more}
            style={{ marginTop: 60 }}
            onClick={() =>
              placesWrapper.current.scrollIntoView({
                behavior: "smooth",
              })
            }
            >
              {t.showMore}
              <span>
                <BsChevronDoubleDown />
              </span>
            </Button>
            } */}
          </div>
          {/* {stateData?.state_categories?.length > 6 &&
          <section className={styles.places_wrapper_holder} ref={placesWrapper}>
            <PlacesWrapper service list={stateData?.state_categories?.slice(6, 16) || []} />
            { stateData?.state_categories?.length > 15 && <PlacesWrapper service list={stateData?.state_categories?.slice(16, 26) || []} /> }
            { stateData?.state_categories?.length > 25 && <PlacesWrapper service list={stateData?.state_categories?.slice(26, 36) || []} /> }
            </section>
} */}
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default ChinaGateDetails;
