import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Dropdown, Image } from "antd";
import arrow_right from "../../images/arrow-right.svg";
import { useSelector } from "react-redux";
import * as Antd from "antd";
import chevronDown from "../../images/chevron-down.svg";

import en from "../../localization/en";
import ar from "../../localization/ar";

import styles from "./Menu.module.scss";
function Menu({ visible, setVisible }) {
  const [brandes, setbrandes] = useState(null);

  const { currentLang } = useSelector((state) => state.user);
  const t = currentLang === "en" ? en : ar;

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/brands`)
      .then((response) => response.json())
      .then((data) => setbrandes(data?.data?.brand));
  }, []);
  return (
    <section
      className={`section-vh ${styles.menu} ${
        visible ? styles.menu_visible : undefined
      }`}
    >
      <div className={styles.bg_color}></div>
      <nav className={styles.nav}>
        <ul>
          <li>
            <Link to="/">
              <a onClick={() => setVisible(false)}>
                <span>01</span>
                {t.home}
              </a>
            </Link>
          </li>
          <li>
            <Link to="/about">
              <a onClick={() => setVisible(false)}>
                <span>02</span>
                {t.about}
              </a>
            </Link>
          </li>
          {/* <li>
            <Link to="/hic-group">
              <a onClick={() => setVisible(false)}>
                <span>03</span>
                {t.hic_group}
              </a>
            </Link>
          </li> */}
          <li>
            <Dropdown
              overlay={
                <Antd.Menu>
                  {brandes?.map((brnad) => (
                    <Antd.Menu.Item key={brnad?.id}>
                      <Link
                        to={{
                          pathname: `/hic-group/${brnad?.id}`,
                        }}
                        rel="noopener noreferrer"
                      >
                        {brnad?.title_en}
                      </Link>
                    </Antd.Menu.Item>
                  ))}
                </Antd.Menu>
              }
              arrow={{ pointAtCenter: true }}
              trigger={["hover"]}
            >
              <a onClick={() => setVisible(false)}>
                <span>03</span>
                {t.hic_group}
                <img
                  src={chevronDown}
                  style={{ width: "2rem", margin: ".5rem" }}
                  alt="icon"
                />
              </a>
              {/* <a className={`ant-dropdown-link ${styles.link_with_arrow}`}>
                {t.HICGroup}
              </a> */}
            </Dropdown>
          </li>
          {/* <li>
            <Link href="/services">
              <a onClick={() => setVisible(false)}>
                <span>04</span>
                Services
              </a>
            </Link>
          </li> */}
          <li>
            <Link to="/media">
              <a onClick={() => setVisible(false)}>
                <span>04</span>
                {t.media}
              </a>
            </Link>
          </li>
          <li>
            <Link to="/china-gate">
              <a onClick={() => setVisible(false)}>
                <span>05</span>
                {t.china_gate}
              </a>
            </Link>
          </li>
          <li>
            <Link to="/kaifa">
              <a onClick={() => setVisible(false)}>
                <span>05</span>
                {t.kaifa}
              </a>
            </Link>
          </li>
          <li className={styles.img_holder}>
            <Image src={arrow_right} preview={false} width={60} />
          </li>
          <li>
            <Link to="/contact-us">
              <a onClick={() => setVisible(false)}> {t.contact_us}</a>
            </Link>
          </li>
        </ul>
      </nav>
    </section>
  );
}

export default Menu;
