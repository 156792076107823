import styles from "./Services.module.scss";
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
import Slider from "react-slick";
import { Link, withRouter } from "react-router-dom";
import { Image } from "antd";
import sevices_img from "../../images/services-img.svg";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <HiArrowNarrowRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <HiArrowNarrowLeft />
    </div>
  );
}

const settings = {
  // dots: false,
  infinite: true,
  speed: 800,
  slidesToShow: 4.2,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  centerMode: true,

  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 3.8,
      },
    },
    {
      breakpoint: 1450,
      settings: {
        slidesToShow: 3.1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2.8,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2.4,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1.8,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1.5,
      },
    },

    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1.5,
        centerMode: false,
      },
    },
    {
      breakpoint: 460,
      settings: {
        slidesToShow: 0.8,
      },
    },
  ],
};

function Services({ services, t }) {
  return (
    <section className="section-vhh">
      <section className={`services_wrapper ${styles.services_wrapper}`}>
        <div className={`container ${styles.container}`}>
          <div className={styles.services_content_holder}>
            <div className={styles.services_content_text} data-aos="fade-up">
              <h4>{t.ourservices}</h4>
              <h2 className={`solid-txt ${styles.solid_txt}`}>
                {t.hic_services}
              </h2>
              <div className={styles.row}>
                <p>{t.services_description}</p>
                {/* <Link href="">
                  <a className={styles.go_link}>
                    <HiArrowNarrowLeft
                      className={`${styles.icon} ${styles.icon_left}`}
                    />
                    All Services
                    <HiArrowNarrowRight
                      className={`${styles.icon} ${styles.icon_right}`}
                    />
                  </a>
                </Link> */}
              </div>
            </div>
            <div className={styles.slider_holder} data-aos="fade-up">
              <Slider {...settings}>
                {/* START */}
                {services?.map((ser) => (
                  <div key={ser?.id}>
                    <div className={styles.slide_content_holder}>
                      <div
                        className={styles.slide_content}
                        style={{
                          backgroundImage: "url(" + ser?.image + ")",
                        }}
                      >
                        <h5>{ser?.title_en}</h5>
                        <Link
                          to={{
                            pathname: `/hic-group/${ser?.brand_id}`,
                          }}
                        >
                          <a className={styles.more_link}>
                            {t.more}
                            <HiArrowNarrowRight />
                          </a>
                        </Link>
                        <div className={styles.border}></div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* <div>
                  <div className={styles.slide_content_holder}>
                    <div className={styles.slide_content}>
                      <h5>Shipping and Warehousing</h5>
                      <Link href="">
                        <a className={styles.more_link}>
                          More
                          <HiArrowNarrowRight />
                        </a>
                      </Link>
                      <div className={styles.border}></div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.slide_content_holder}>
                    <div className={styles.slide_content}>
                      <h5>Shipping and Warehousing</h5>
                      <Link href="">
                        <a className={styles.more_link}>
                          More
                          <HiArrowNarrowRight />
                        </a>
                      </Link>
                      <div className={styles.border}></div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.slide_content_holder}>
                    <div className={styles.slide_content}>
                      <h5>Shipping and Warehousing</h5>
                      <Link href="">
                        <a className={styles.more_link}>
                          More
                          <HiArrowNarrowRight />
                        </a>
                      </Link>
                      <div className={styles.border}></div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.slide_content_holder}>
                    <div className={styles.slide_content}>
                      <h5>Shipping and Warehousing</h5>
                      <Link href="">
                        <a className={styles.more_link}>
                          More
                          <HiArrowNarrowRight />
                        </a>
                      </Link>
                      <div className={styles.border}></div>
                    </div>
                  </div>
                </div> */}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default Services;
