import { Image } from "antd";
import { usePreviewIcons } from "../../../hooks/usePreviewIcons";
import React, { useState } from "react";

export default function ImageWithViewer(props) {
  const { coverSrc, alt, className, gallery = [], ...rest } = props;
  const [visible, setVisible] = useState(false);
  const { icons } = usePreviewIcons(visible);
  return (
    <div className={className} {...rest}>
      <Image
        src={coverSrc}
        preview={{
          visible: false,
        }}
        onClick={() => setVisible(true)}
        alt={alt || "image"}
      />
      <div
        style={{
          display: "none",
        }}
      >
        <Image.PreviewGroup
          preview={{
            visible,
            onVisibleChange: (vis) => setVisible(vis),
          }}
          icons={icons}
        >
          {React.Children.toArray(
            gallery?.map((src) => <Image src={src} alt={"image"} />)
          )}
        </Image.PreviewGroup>
      </div>
    </div>
  );
}
