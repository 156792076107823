import React, { useState, useEffect } from "react";
import {
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  CloseOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";

export function usePreviewIcons(isVisible) {
  const [currentZoom, setCurrentZoom] = useState(1);

  useEffect(() => {
    resetZoom();
  }, [isVisible]);

  const handleZoom = (step) => (e) => {
    e.stopPropagation();
    const newZoom = currentZoom + step;
    if (newZoom < 0.5) return;
    setCurrentZoom(newZoom);
    const img = document.querySelector(".ant-image-preview-img");
    if (!!img)
      img.style.transform = `scale3d(${newZoom}, ${newZoom}, 1) rotate(0deg)`;
  };
  const increaseZoom = handleZoom(0.3);
  const decreaseZoom = handleZoom(-0.3);

  const resetZoom = () => {
    setCurrentZoom(1);
    const img = document.querySelector(".ant-image-preview-img");
    if (!!img) img.style.transform = `scale3d(1, 1, 1) rotate(0deg)`;
  };

  return {
    icons: {
      rotateLeft: <RotateLeftOutlined />,
      rotateRight: <RotateRightOutlined />,
      zoomIn: <ZoomInOutlined onClick={increaseZoom} />,
      zoomOut: <ZoomOutOutlined onClick={decreaseZoom} />,
      close: <CloseOutlined />,
      left: <LeftOutlined />,
      right: <RightOutlined />,
    },
  };
}
