import React, { useState, useEffect } from "react";
import moment from "moment";
import ContactUS from "../public/components/ContactUS/ContactUS";
import FollowUs from "../public/components/FollowUs/FollowUs";
import HicGroup from "../public/components/HicGroup/HicGroup";
import Statistics from "../public/components/Statistics/Statistics";
import Services from "../public/components/Services/Services";
import SliderHero from "../public/components/SliderHero/SliderHero";
import Header from "../public/components/Header/Header";
import Media from "../public/components/Media/Media";
import HaithamSocials from "../public/components/HaithamSocials/HaithamSocials";
import NewsTickers from "../public/components/NewsTicker/NewsTicker";
import en from "../public/localization/en";
import ar from "../public/localization/ar";
import Footer from "../public/components/Footer/Footer";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Image } from "antd";
import { usePreviewIcons } from "../hooks/usePreviewIcons";
import NewsSection from "../public/components/NewsSection/NewsSection";
import Currency from "../public/components/Currency/Currency";
import Featured from "../public/components/Featured/Featured";

export default function Home() {
  const { currentLang } = useSelector((state) => state.user);
  const t = currentLang === "en" ? en : ar;
  const [homeData, sethomeData] = useState(0);
  const [news, setNews] = useState({});
  const [visible, setVisible] = useState(false);
  const [coinsVisible, setCoinsVisible] = useState(false);
  const history = useHistory();
  const { icons } = usePreviewIcons(visible);
  const current = moment()
  var today = current.format("YYYY-MM-DD")

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/home`)
      .then((response) => response.json())
      .then((data) => sethomeData(data?.data));
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/news`)
      .then((response) => response.json())
      .then((data) => setNews(data?.data));
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);

  return (
    <>
      <SliderHero t={t} />
      <NewsSection
        news = {news?.china_news?.filter(a =>  new Date(a.created_at).getDate() === new Date(today).getDate()).length >= 1 ? news?.china_news?.filter(a =>  new Date(a.created_at).getDate() === new Date(today).getDate()) : [] }
        title={t.chinaNews}
        type="china"
        autoplay={true}
      />
      <NewsSection
        news={news?.world_news?.slice(0, 3) || []}
        title={t.worldNews}
        withSlider={true}
        autoplay={false}
        type="world"
      />
      <div className="coins-ship-holder">
        <div className="container">
          <div className="coins-ship-content">
            {homeData && homeData?.shipping?.shippings && (
              <div>
                <h3 className="china-gate-card--title">{t.shipping}</h3>
                <div className="card-view-holder">
                  <Image
                    src={homeData?.shipping?.cover?.image}
                    preview={{
                      visible: false,
                    }}
                    onClick={() => setVisible(true)}
                    alt=""
                  />
                  <div
                    style={{
                      display: "none",
                    }}
                  >
                    <Image.PreviewGroup
                      preview={{
                        visible,
                        onVisibleChange: (vis) => setVisible(vis),
                      }}
                      icons={icons} 
                    >
                      {homeData?.shipping?.shippings?.map((s) => (
                        <Image key={s?.id} src={s?.image} alt={"image"} />
                      ))}
                    </Image.PreviewGroup>
                  </div>
                </div>
              </div>
            )}
            <div>
              <h3 className="china-gate-card--title">{t.currency}</h3>
              <div className="card-view-holder  card-view-holder__currency">
                <Currency coins={homeData?.coins || []} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="featured-holder" data-aos="fade-up">
        <div className="container">
          <h3 className="china-gate-card--title"> {t.Featured}</h3>
          <Featured data={homeData?.china_gate} />
        </div>
      </div>
      <div className="container">
        <HaithamSocials
          t={t}
          title={t.FindUsOn}
          settings={homeData?.settings}
        />
      </div>
      <section className="container">
        {homeData?.ads?.length && homeData?.ads[0]?.link ? (
          <a
            href={homeData && homeData?.ads[0] && homeData?.ads[0]?.link}
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            target="_blank"
            rel="noopener noreferrer"
            className="ADS-place ADS-place-home"
          >
            <img
              src={homeData && homeData?.ads[0] && homeData?.ads[0]?.image}
              alt=""
            />
          </a>
        ) : (
          <div className="ADS-place ADS-place-home">
            <img
              src={homeData && homeData?.ads[0] && homeData?.ads[0]?.image}
              alt=""
            />
          </div>
        )}
      </section>
      <HicGroup brands={homeData?.brands} t={t} />
      <ContactUS t={t} cover={homeData?.settings?.at(6)?.value} />
      <Footer />
    </>
  );
}
