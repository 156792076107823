import {
  SET_LANG,
} from './user.type';

const INITIAL_STATE = {
  currentLang: "en",
};
const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LANG:
      return {
        ...state,
        currentLang: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
