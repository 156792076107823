import styles from "./Sidenav.module.scss";
import logo from "../../images/Logo.png";
import { Image } from "antd";
import { Link, withRouter } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

import {
  FaFacebookF,
  FaYoutube,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaTiktok,
} from "react-icons/fa";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";

function Sidenav({ visible, handleMenu, t }) {

//   useEffect(() => {
//     window.addEventListener("scroll", handler);
//     return () => window.removeEventListener("scroll", handler);
// }, [])

// const handler = () => {
//     if (
//       document.body.scrollTop > 500 ||
//       document.documentElement.scrollTop > 500
//       ) {
//       }
//   };
  return (
    <div className={styles.Sidenav} data-aos="fade-right">
      <div className={styles.content_holder}>
        <Link to="/" className={styles.brand}>
          <Image preview={false} src={logo} alt="" width={110} height={82} />
        </Link>
        <button
          className={`btn ${styles.btn_menu} ${
            visible ? styles.btn_close : undefined
          }`}
          onClick={() => handleMenu((status) => !status)}
        >
          <div className={styles.bars}>
            <span></span>
            <span></span>
          </div>
          {!visible && t.menu}
        </button>
        <nav className={styles.nav}>
          <ul>
          <li>
                  <a target="_blank"
                      rel="noreferrer" href="https://www.facebook.com/HaithaminChina">
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a target="_blank"
                      rel="noreferrer" href="https://www.youtube.com/c/haithaminchina/featured">
                    <FaYoutube />
                  </a>
                </li>
                <li>
                  <a target="_blank"
                      rel="noreferrer" href="https://www.twitter.com/HaithaminChina">
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a target="_blank"
                      rel="noreferrer" href="https://www.instagram.com/haithaminchina">
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a target="_blank"
                      rel="noreferrer" href="https://www.linkedin.com/company/71660921">
                    <FaLinkedinIn />
                  </a>
                </li>
                <li>
                  <a target="_blank"
                      rel="noreferrer" href="https://www.tiktok.com/@haithaminchina">
                    <FaTiktok />
                  </a>
                </li>
          </ul>
        </nav>
        <button
          className={`btn ${styles.btn_scroll}`}
          onClick={() => scroll.scrollToBottom()}
        >
          {t.scroll_down}
          <HiOutlineArrowNarrowDown />
        </button>
      </div>
    </div>
  );
}

export default Sidenav;
