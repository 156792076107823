

import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { useLocalization } from "../../hooks/useLocalization";
import { Helmet } from "react-helmet";
import moment from "moment";

import { Row, Col, Image } from "antd";
import TimeDisplay from "../../public/components/TimeDisplay/TimeDisplay";

import NewsSectionDate from "../../public/components/NewsSectionDate/NewsSectionDate";

function WorldNews(props) {
    const { t, currentLang } = useLocalization();
    const [allNews, setAllNews] = useState([]);
    var days = [1,2,3,4,5,6,7];
    var resultDates = [];
    const current = moment()
    let n=8
    while(n>0){
        console.log(n)
        resultDates.push(current.format("YYYY-MM-DD"))
        current.subtract(1,"day")
        n--;
        
    }


    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/news`)
          .then((response) => response.json())
          .then((data) => {
           setAllNews(data?.data?.world_news || [])
          });
          console.log("allNews china" , allNews)
    
        const body = document.querySelector("#root");
        body.scrollIntoView(
          {
            behavior: "smooth",
          },
          500
        );
      }, []);
          return (
            <>

                    <div className="slider_holder " data-aos="zoom-in">
                    {resultDates.map((s) => (
                        <>
                         <NewsSectionDate
                         withSlider = {true}
                          date = {s}
                         news={allNews}
                         title= {<TimeDisplay date={s} />}
                         type="world"
                         />
                         </>
                      ))}
                   
                </div>


            </>
          )

}

export default WorldNews;
