import React from "react";
import { Modal } from "antd";
import { FaTimes } from "react-icons/fa";
import styles from "./OwnModal.module.scss";

function OwnModal({
  isModalVisible,
  onOk,
  onCancel,
  children,
  footer,
  width,
  closable,
  closeIcon,
  model_unit
}) {
  return (
    <Modal
      title={null}
      closable={closable || false}
      visible={isModalVisible}
      onOk={onOk}
      onCancel={onCancel}
      footer={footer || null}
      width={width}
      className={styles.own_modal}
      centered={true}
      // maskStyle={{ background: "#000", opacity: "0.83" }}
      closeIcon={closeIcon || <FaTimes className={styles.close_icon} />}
      // bodyStyle={{ background: "blue" }}
      wrapClassName={`${styles.modal_wrapper} ${model_unit && " model-unit-wrapper"}  `}
      bodyStyle={{
        padding: "0",
      }}
    >
      <div className={styles.modal_inner}>{children}</div>
    </Modal>
  );
}

export default OwnModal;
