import { useRef, useState } from "react";
import { Image } from "antd";
import ReactPlayer from "react-player";

export default function SliderMediaWrapper({
  media,
  playingVideo,
  handleStopVideo,
  handlePlayVideo,
  handleMediaClick,
  wrapperClassNames,
  mediaClassNames,
}) {
  const ref = useRef(null);
  const isInView = (el) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  return (
    <div className={wrapperClassNames}>
      <div className={mediaClassNames} ref={ref}>
        {media?.video || media?.facebook ? (
          <ReactPlayer
            width="100%"
            height="100%"
            volume={0.5}
            url={media?.video || media?.facebook}
            config={{
              facebook: {
                attributes: {
                  "data-height": "388px",
                },
              },
            }}
            playing={playingVideo && isInView(ref.current)}
            onEnded={handleStopVideo}
            onStart={handlePlayVideo}
            onPlay={handlePlayVideo}
            onPause={handleStopVideo}
          />
        ) : (
          <Image
            src={media?.image}
            preview={false}
            onClick={handleMediaClick}
          />
        )}
      </div>
    </div>
  );
}
