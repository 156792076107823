import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
import Slider from "react-slick";
import { Link } from "react-router-dom";
// import styles from "../../../styles/pages/hic_grop.module.scss";
import styles from "./BrandsSlider.module.scss";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <HiArrowNarrowRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <HiArrowNarrowLeft />
    </div>
  );
}
const settings = {
  infinite: true,
  speed: 800,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  centerMode: true,
  variableWidth: true,
};

function BrandsSlider({ brands }) {
  return (
    <div className={`apps_slider_holder ${styles.apps_slider_holder}`} data-aos="fade-up">
      <Slider {...settings}>
        {/* START */}
        {brands?.map((brand) => (
          <div key={brand?.id} style={{ width: "250px" }}>
            <div className={styles.slide_content_holder}>
              <div className="image_holder">
                <Link
                  to={{
                    pathname: `/hic-group/${brand?.id}`,
                  }}
                >
                  {brand?.image && (
                    <img
                      src={brand?.image}
                      width={150}
                      height={120}
                      alt="brand"
                    />
                  )}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
export default BrandsSlider;
