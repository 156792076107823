import React from "react";
import styles from "./FollowUs.module.scss";
import { Helmet } from "react-helmet";
import {
  FaFacebook,
  FaTwitter,
  FaYoutube,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
} from "react-icons/fa";
import { RiArrowRightUpLine } from "react-icons/ri";
function FollowUs({ t, showFind }) {
  return (
    <>
      <Helmet>
        <script src="https://apis.google.com/js/platform.js"></script>
        <script src="https://www.google-analytics.com/analytics.js"></script>
      </Helmet>
      <section className="section-vh">
        <div className={styles.hic_group_section}>
          {          
          showFind ? 
          <div className={styles.main_title} data-aos="fade-right">
            {t.FindUsOn}
          </div> :
          <div className={styles.section_info_holder}>
            <div className={styles.section_content_holder}>
              <div className={styles.info_left_conetnt} data-aos="fade-up">
                <h3 className={styles.section_headline}>
                  {t.find_us_on_social_media}
                </h3>
                <p className={styles.section_p}>{t.follow_us_description}</p>
              </div>
              <div className="info-right-conetnt">
                <h2 className="solid-txt yellow-text" data-aos="fade-left">
                  {t.haitham} <br></br>
                  {t.in_china}
                </h2>
              </div>
            </div>
          </div>
          }

          <div className={styles.social_grid}>
            <div
              className={`${styles.social_card_holder} ${styles.facbook}`}
              data-aos="flip-left"
            >
              <div className={styles.social_card_icon}>
                <FaFacebook />
                {/* <p className={styles.social_text}>
                Like
                <RiArrowRightUpLine />
              </p> */}
                <p className={styles.social_text}>
                  <iframe
                    src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2FHaithaminChina&width=500&layout=button&action=like&size=large&share=false&height=65&appId=136578575225554"
                    width={80}
                    height={28}
                    scrolling="no"
                    frameBorder={0}
                    allowFullScreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  />
                </p>
              </div>
            </div>
            <div
              className={`${styles.social_card_holder} ${styles.youtub}`}
              data-aos="flip-left"
            >
              <div className={styles.social_card_icon}>
                <FaYoutube />
                <p className={styles.social_text}>
                  {/* subscribe
                <RiArrowRightUpLine /> */}
                  <div
                    className="g-ytsubscribe"
                    data-channelid="UCVPyHBp5T0zjpL2Z4RFlLsA"
                    data-layout="default"
                    data-count="hidden"
                  ></div>
                  {/* <div className="g-ytsubscribe" data-channelid="UCVPyHBp5T0zjpL2Z4RFlLsA" data-layout="default" data-count="default"></div> */}
                </p>
              </div>
            </div>
            <div
              className={`${styles.social_card_holder} ${styles.twitter}`}
              data-aos="flip-left"
            >
              <div className={styles.social_card_icon}>
                <FaTwitter />
                {/* Follow
                <RiArrowRightUpLine /> */}
                <a
                  href="https://www.twitter.com/HaithaminChina"
                  target="_blank"
                  className={styles.social_text}
                >
                  {t.follow}
                  <RiArrowRightUpLine />
                </a>
                {/* <a
                  href="https://twitter.com/share?ref_src=haithaminchina"
                  className="twitter-share-button"
                  data-show-count="false"
                >
                   Follow
                  <RiArrowRightUpLine /> 
                </a> */}
              </div>
            </div>
            <div
              className={`${styles.social_card_holder} ${styles.insta}`}
              data-aos="flip-left"
            >
              <div className={styles.social_card_icon}>
                <FaInstagram />
                <a
                  href="https://instagram.com/haithaminchina"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.social_text}
                >
                  {t.follow}
                  <RiArrowRightUpLine />
                </a>
              </div>
            </div>
            <div
              className={`${styles.social_card_holder} ${styles.linkdin}`}
              data-aos="flip-left"
            >
              <div className={styles.social_card_icon}>
                <FaLinkedin />
                <a
                  href="https://www.linkedin.com/company/71660921"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.social_text}
                >
                  {t.follow}
                  <RiArrowRightUpLine />
                </a>
                {/* <p className={styles.social_text}>
                  {t.follow}
                  <RiArrowRightUpLine />
                </p> */}
              </div>
            </div>
            <div
              className={`${styles.social_card_holder} ${styles.tiktok}`}
              data-aos="flip-left"
            >
              <div className={styles.social_card_icon}>
                <FaTiktok />
                <a
                  href="https://www.tiktok.com/@haithaminchina"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.social_text}
                >
                  {t.follow}
                  <RiArrowRightUpLine />
                </a>
                {/* <p className={styles.social_text}>
                  {t.follow}
                  <RiArrowRightUpLine />
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FollowUs;
