import styles from "./FloatingWhatsup.module.scss";
import { BsWhatsapp } from "react-icons/bs";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import { useEffect, useState } from "react";
import whatsapp from "../../images/whatsapp.png";

function FloatingWhatsup() {
  const [isVisible, setIsVisible] = useState(true);

  // const toggleVisibility = () => {
  //   if (window.pageYOffset > 600) setIsVisible(true);
  //   else setIsVisible(false);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", toggleVisibility);
  //   return () => window.removeEventListener("scroll", toggleVisibility);
  // }, []);

  return isVisible ? (
    <button
      className={`btn ${styles.button_floating}`}
      onClick={() => {
        window.open(`https://wa.me/+8618529461301`)
      }}
      // +2 01003566929
    >
      {/* <BsWhatsapp /> */}
      <img src={whatsapp} alt="" />
    </button>
  ) : (
    ""
  );
}

export default FloatingWhatsup;
