import styles from "./ButtonFloating.module.scss";
import { BsArrowUp } from "react-icons/bs";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import { useEffect, useState } from "react";

function ButtonFloating() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 600) setIsVisible(true);
    else setIsVisible(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return isVisible ? (
    <button
      className={`btn ${styles.button_floating}`}
      onClick={() => scroll.scrollToTop()}
    >
      <BsArrowUp />
    </button>
  ) : (
    ""
  );
}

export default ButtonFloating;
