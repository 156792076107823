import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import About from "./pages/About";

import ChinaGate from "./pages/ChinaGate/ChinaGate";
import ChinaGateDetails from "./pages/ChinaGateDetails/ChinaGateDetails";
import ChinaGateDetailsService from "./pages/ChinaGateDetailsService/ChinaGateDetailsService";
import ContactUs from "./pages/ContactUs";
import SingleBrand from "./pages/hic-group/SingleBrand";
import Home from "./pages/Home";
import Kaifa from "./pages/Kaifa";
import MediaPage from "./pages/media/MediaPage";
import SingleArticle from "./pages/SingleArticle/SingleArticle";
import ServicePage from "./pages/Service/ServicePage";
import SingleMedia from "./pages/SingleMedia/SingleMedia";
import NewsPage from "./pages/NewsPage/NewsPage";



import WorldNews from "./pages/News/WorldNews";
import ChinaNews from "./pages/News/ChinaNews";


const base = "/:locale(en|ar)?";
console.log(base);

class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={"/"} render={(props) => <Home {...props} />} />
        <Route exact path={"/about"} render={(props) => <About {...props} />} />

        <Route exact path={"/kaifa"} render={(props) => <Kaifa {...props} />} />
        <Route
          exact
          path={"/contact-us"}
          render={(props) => <ContactUs {...props} />}
        />
        <Route
          exact
          path={"/media"}
          render={(props) => <MediaPage {...props} />}
        />
        <Route
          exact
          path={"/hic-group/:id"}
          render={(props) => <SingleBrand {...props} />}
        />
        <Route
          exact
          path={"/article/:id"}
          render={(props) => <SingleArticle {...props} />}
        />
        <Route
          exact
          path={"/china-gate"}
          render={(props) => <ChinaGate {...props} />}
        />
        <Route
          exact
          path={"/china-gate/state/:id"}
          render={(props) => <ChinaGateDetails {...props} />}
        />
        <Route
          exact
          path={"/china-gate-service/:id"}
          render={(props) => <ChinaGateDetailsService {...props} />}
        />
        <Route
          exact
          path={"/service/:id"}
          render={(props) => <ServicePage {...props} />}
        />
        <Route
          exact
          path={"/china-news/:id"}
          render={(props) => <NewsPage {...props} type="china" />}
        />
        <Route
          exact
          path={"/world-news/:id"}
          render={(props) => <NewsPage {...props} type="world" />}
        />
      <Route
          exact
          path={"/world-news"}
          render={(props) => <WorldNews {...props} />}
        />
           <Route
          exact
          path={"/china-news"}
          render={(props) => <ChinaNews {...props} />}
        />
        <Route
          exact
          path={"/events/:id"}
          render={(props) => <SingleMedia {...props} type="events" />}
        />
        <Route
          exact
          path={"/world_information/:id"}
          render={(props) => (
            <SingleMedia {...props} type="world_information" />
          )}
        />
      </Switch>
    );
  }
}

export default Routes;
