import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import cls from "classnames";
import styles from "./SinglePlace.module.scss";


// https://admin.haithaminchina.com/
function SinglePlace({ img, title, link, onClick, service, small, style, id, isUnit, handeSetActiveunit, unitData }) {
  // console.og("img",img);
  const content = (
    <div
      className={cls(
        styles.single_place,
        { [styles.small]: small },
        {
          [styles.small_card]: service,
        }
      )}
      onClick={onClick}
      style={{ backgroundImage: `url(${img})`, ...style }}
    >
      <div className={styles.text_wrapper}>
        <div className={styles.title}>
          {/* {!service && <span>{title.ar}</span>} */}
          <span>{title.ar}</span>
        </div>
      </div>
    </div>
  );

  if(isUnit) return <div 
  onClick={(s) => {
    console.log("isUnitisUnitisUnit" ,isUnit)
    if(isUnit){
      handeSetActiveunit(unitData) 
    }
  }}
  >{content}</div>;
  else if (link && service)  return <Link to={`/china-gate-service/${id}`}>{content}</Link>;
  else if (link) return <Link to={`/china-gate/state/${id}`}>{content}</Link>;
  return content;
}

export default SinglePlace;
