import ReactPlayer from "react-player";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { Link, useHistory, withRouter } from "react-router-dom";
import { useState } from "react";
import styles from "./SingleVideo.module.scss";
import en from "../../localization/en";
import ar from "../../localization/ar";
import { useSelector } from "react-redux";
import { Image } from "antd";

function SingleVideo({ video }) {
  const [playVideo, setPlayVideo] = useState(false);
  const { currentLang } = useSelector((state) => state.user);
  const t = currentLang === "en" ? en : ar;
  const history = useHistory();
  // console.log(video, "videovideovideo")
  const handlePlayVideo = () => {
    setPlayVideo(true);
  };
  const handleVideoStop = () => {
    setPlayVideo(false);
  };
  const handleMediaClick = () => {
    if (video?.category_media_id === 7) {
      history.push(`/article/${video?.id}`);
    } else if (video?.category_media_id === "events") {
      history.push(`/events/${video?.id}`);
    } else if (video?.category_media_id === "world_information") {
      history.push(`/world_information/${video?.id}`);
    }
  };
  return (
    <div
      className={styles.single_video}
      // data-aos="fade-up"
      onClick={handleMediaClick}
    >
      <div className={styles.video_holder}>
        {video?.image ? (
          <div className="media-img-holder">
            <Image
              src={video?.image}
              alt={video?.image?.title_ar}
              preview={video?.category_media_id === 5}
            />
            {/* <img src={video?.image } alt={video?.image?.title_ar} /> */}
          </div>
        ) : (
          <ReactPlayer
            width="100%"
            height="100%"
            volume={0.5}
            url={video?.video || video?.facebook}
            // controls={true}
            playing={playVideo}
            controls={playVideo}
            onEnded={handleVideoStop}
            onPause={handleVideoStop}
          />
        )}
        <h4 className={styles.video_title}>
          {currentLang === "en" ? video?.title_en : video?.title_ar}
        </h4>
      </div>

      {/* <div
        className={`${styles.box_floating} ${
          !playVideo ? styles.box_floating_active : ""
        }`}
      >
        <div className={styles.box_floating_content}>
          <BsFillPlayCircleFill
            className={styles.play_icon}
            title="Play video"
            style={{ display: playVideo ? "none" : "block" }}
            onClick={handlePlayVideo}
          />
          <h4 className={styles.video_title}>{currentLang === "en" ? video?.title_en : video?.title_ar}</h4>
        </div>
      </div> */}
    </div>
  );
}

export default SingleVideo;
