import { Button, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import "react-phone-number-input/style.css";
import styles from "./ContactUS.module.scss";
import { Row, Col } from "antd";

function ContactUS({ t, cover }) {
  const [form] = Form.useForm();
  const [registerLoading, setloginLoading] = useState(false);

  const handelContactReq = (values) => {
    setloginLoading(true);
    let body = new FormData();
    body.append("name", values?.name);
    body.append("email", values?.email);
    body.append("phone", values?.phone);
    body.append("message", values?.message);

    axios({
      method: "post",
      Accept: "application/json",
      url: `${process.env.REACT_APP_API_BASE_URL}/api/newContactRequest`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: body,
    })
      .then(function (response) {
        setloginLoading(false);
        console.log(response);
        if (response?.data?.success) {
          message.success("message send successfully");
          form.resetFields();
        }
      })
      .catch(function (error) {
        setloginLoading(false);
        message.error(error?.response?.data?.errors?.phone[0]);
      });
  };

  return (
    <section className="section-vh contact-us-page-holder">
      <div className={styles.contact_us_wrapper}>
        <div className={`container ${styles.container}`}>
          <h2 data-aos="fade-down">{t.contact_us}</h2>

          <div className={`${styles.contact_us_content}`} data-aos="fade-up">
            <Form form={form} name="basic" onFinish={handelContactReq}>
              <Form.Item
                name="name"
                rules={[
                  { required: true, message: `${t.Pleaseinput} ${t.name}` },
                ]}
                className="input-holder"
              >
                <Input placeholder={t.full_name} />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: `${t.Pleaseinput} ${t.EmailAddress}`,
                  },
                  { type: "email", message: t.Emailvalid },
                ]}
                className="input-holder"
              >
                <Input placeholder={t.email} />
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: `${t.Pleaseinput} ${t.Phonenumber}`,
                  },
                ]}
                className="input-holder"
              >
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="EG"
                  // value={numberVal}
                  // onChange={(val) => setnumberVal(val)}
                />
              </Form.Item>
              <Form.Item
                name="message"
                rules={[
                  {
                    required: true,
                    message: `${t.Pleaseinput} ${t.message}`,
                  },
                ]}
                className="input-holder"
              >
                <Input.TextArea placeholder={t.message} rows={4} />
              </Form.Item>
              <Button
                icon={<FaLongArrowAltRight />}
                loading={registerLoading}
                type="primary"
                size="large"
                htmlType="submit"
                block
              >
                {t.send}
              </Button>
              {/* <div className="g-ytsubscribe" data-channelid="UCOkAbnpafkFW_TWWBvrUutA" data-layout="default" data-count="default"></div> */}
              {/* <iframe
              src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2FHaithaminChina&width=72&layout=button&action=like&size=large&share=false&height=65&appId=136578575225554"
              width="72"
              height="65"
              scrolling="no"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe> */}
            </Form>
            {/* placeholder image */}
            <div className={styles.contact_us_image}>
              <img src={cover} alt="contact us" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUS;
