import React from "react";
import styles from "./HaithamSocials.module.scss";
import {
  FaFacebook,
  FaTwitter,
  FaYoutube,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
} from "react-icons/fa";
import en from "../../localization/en";
import ar from "../../localization/ar";
import { AiOutlineLine } from "react-icons/ai";
import { FiArrowUpRight } from "react-icons/fi";
import { RiArrowRightUpLine } from "react-icons/ri";
import { useSelector } from "react-redux";
function HaithamSocials({ title, settings }) {
  const { currentLang } = useSelector((state) => state.user);
  const t = currentLang === "en" ? en : ar;

  const followers = {
    facebook: settings?.at(0)?.value,
    youtube: settings?.at(1)?.value,
    instagram: settings?.at(2)?.value,
    twitter: settings?.at(3)?.value,
    linkedin: settings?.at(4)?.value,
    tiktok: settings?.at(5)?.value,
  };
  return (
    <div className={styles.find_us}>
      <div className={styles.main_title}>{title}</div>
      <div className={styles.social_grid}> 
        <a
          href="https://www.facebook.com/HaithaminChina"
          data-aos="flip-left"
          target="_blank"
          className={`${styles.social_card_holder} ${styles.facbook}`}
        >
          <span className={styles.border_half}>
            <FaFacebook />
          </span>
          <div className={styles.social_card_icon}>
            <div className={styles.social_count}>{followers?.facebook}</div>
            <span className={styles.social_count_txt}>{t.Followers}</span>
            <a
              href="https://www.facebook.com/HaithaminChina"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.social_text}
            >
              {t.Like}
              <RiArrowRightUpLine />
            </a>
          </div>
        </a>
        <a
          href="https://www.youtube.com/c/haithaminchina/featured"
          data-aos="flip-left"
          target="_blank"
          className={`${styles.social_card_holder} ${styles.youtub}`}
        >
          <span className={styles.border_half}>
            <FaYoutube />
          </span>
          <div className={styles.social_card_icon}>
            <div className={styles.social_count}>{followers?.youtube}</div>
            <span className={styles.social_count_txt}>{t.Followers}</span>
            <a
              href="https://www.youtube.com/c/haithaminchina/featured"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.social_text}
            >
              {t.subscribe}
              <RiArrowRightUpLine />
            </a>
          </div>
        </a>
        <a
          href="https://www.twitter.com/HaithaminChina"
          data-aos="flip-left"
          target="_blank"
          className={`${styles.social_card_holder} ${styles.twitter}`}
        >
          <span className={styles.border_half}>
            <FaTwitter />
          </span>
          <div className={styles.social_card_icon}>
            <div className={styles.social_count}>{followers?.twitter}</div>
            <span className={styles.social_count_txt}>{t.Followers}</span>
            <a
              href="https://www.twitter.com/HaithaminChina"
              target="_blank"
              className={styles.social_text}
            >
              {t.follow}
              <RiArrowRightUpLine />
            </a>
          </div>
        </a>
        <a
          href="https://instagram.com/haithaminchina"
          data-aos="flip-left"
          target="_blank"
          className={`${styles.social_card_holder} ${styles.insta}`}
        >
          <span className={styles.border_half}>
            <FaInstagram />
          </span>
          <div className={styles.social_card_icon}>
            <div className={styles.social_count}>{followers?.instagram}</div>
            <span className={styles.social_count_txt}>{t.Followers}</span>
            <a
              href="https://instagram.com/haithaminchina"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.social_text}
            >
              {t.follow}
              <RiArrowRightUpLine />
            </a>
          </div>
        </a>
        <a
          href="https://www.linkedin.com/company/71660921"
          data-aos="flip-left"
          target="_blank"
          className={`${styles.social_card_holder} ${styles.linkdin}`}
        >
          <span className={styles.border_half}>
            <FaLinkedin />
          </span>
          <div className={styles.social_card_icon}>
            <div className={styles.social_count}>{followers?.linkedin}</div>
            <span className={styles.social_count_txt}>{t.Followers}</span>
            <a
              href="https://www.linkedin.com/company/71660921"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.social_text}
            >
              {t.follow}
              <RiArrowRightUpLine />
            </a>
          </div>
        </a>
        <a
          href="https://www.tiktok.com/@haithaminchina"
          data-aos="flip-left"
          target="_blank"
          className={`${styles.social_card_holder} ${styles.TikTok}`}
        >
          <span className={styles.border_half}>
            <FaTiktok />
          </span>
          <div className={styles.social_card_icon}>
            <div className={styles.social_count}>{followers?.tiktok}</div>
            <span className={styles.social_count_txt}>{t.Followers}</span>
            <a
              href="https://www.tiktok.com/@haithaminchina"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.social_text}
            >
              {t.follow}
              <RiArrowRightUpLine />
            </a>
          </div>
        </a>
      </div>
    </div>
  );
}

export default HaithamSocials;
