import Slider from "react-slick";
import styles from "./Currency.scss";
import { Card } from "antd";
import { SwapOutlined } from "@ant-design/icons";
import { RiArrowRightUpLine } from "react-icons/ri";
import { useLocalization } from "../../../hooks/useLocalization";
import React from "react";

function Currency({ coins = [] }) {
  const { currentLang } = useLocalization();
  if (coins?.length === 0) return null;
  /**
   * This is a temporary solution to fix the issue of the slider not working properly
   * There is a bug in the animation that causes the slider to flicker when the
   * slider switches from the last item to the first item.
   * The solution is to add duplicate items to the end of the array to make the flicker appear once after 100 items
   * By doing this, it will take long time for the flicker to appear again.
   * It is a bad solution but it is good for now. It may cause performance issues for poor devices.
   */
  const coinsList = [...coins];
  while (coinsList.length < 100) {
    coinsList.push(...coins);
  }
  
  return (
    <div className={`coins-wrapper`} data-aos="fade-up">
      <div
        style={{
          animationDuration: `${coinsList.length * 3}s`,
        }}
      >
        {React.Children.toArray(
          coinsList.map((coin) => (
            <CoinCard
              title={currentLang === "en" ? coin.title_en : coin.title_ar}
              buy={coin.buy}
              sell={coin.sell}
              image={coin.image}
            />
          ))
        )}
      </div>
    </div>
  );
}
export default Currency;

const CoinCard = ({ title = "", sell, buy, image }) => {
  const { t } = useLocalization();
  return (
    <Card
      hoverable
      className="coins-card"
      cover={<img alt="news" src={image} />}
    >
      <Card.Meta className={"coins-card__title"} title={title} />
      <div className="coins-card__price-change">
        <div className="coins-card__change">
          <span className="icon">
            <RiArrowRightUpLine />
          </span>
          {t.buy}: {buy}
        </div>
        <div className="coins-card__price">
          <span className="icon">
            <RiArrowRightUpLine />
          </span>
          {t.sell}: {sell}
        </div>
      </div>
    </Card>
  );
};
