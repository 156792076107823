import { Breadcrumb, Col, Row, Button, Image } from "antd";
import { AiOutlineLine } from "react-icons/ai";
import { Link, withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import Footer from "../public/components/Footer/Footer";
import { FiArrowUpRight } from "react-icons/fi";
import img_1 from "../public/images/person_img.png";
import img_2 from "../public/images/certificate_img.svg";
import img_3 from "../public/images/person_img-2.png";
import logo_1 from "../public/images/img_1.jpeg";
import logo_2 from "../public/images/img_2.jpeg";
import logo_3 from "../public/images/img_3.jpeg";
import logo_4 from "../public/images/img_4.jpeg";
import logo_5 from "../public/images/img_5.jpeg";
import en from "../public/localization/en";
import ar from "../public/localization/ar";
import img_4 from "../public/images/logo_placeholder.svg";

import {
  FaFacebookF,
  FaYoutube,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaTiktok,
} from "react-icons/fa";

import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
import styles from "../styles/pages/about.module.scss";
import HaithamSocials from "../public/components/HaithamSocials/HaithamSocials";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

function About() {
  const { currentLang } = useSelector((state) => state.user);
  const t = currentLang === "en" ? en : ar;
  const [homeData, sethomeData] = useState(0);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/home`)
    .then((response) => response.json())
    .then((data) => sethomeData(data?.data));
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
    document.getElementById("main-header").classList.add("active");
  }, []);

  return (
    <>
      <div className={`about_page ${styles.about_page}`}>
        <Helmet>
          <title>{t.about}</title>
          <meta name="title" content={t.about} />
          <meta property="og:title" content={t.about} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
        <div className="container">
          <div className={styles.about_page_holder}>
            <Breadcrumb
              data-aos="fade-right"
              className={`breadcrumb ${styles.breadcrumb}`}
              separator={<AiOutlineLine />}
            >
              <Breadcrumb.Item className="breadcrumb-to">
                <Link to="/">{t.home}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-current">
                {t.about}
              </Breadcrumb.Item>
            </Breadcrumb>
            <div
              className={`header_page_box ${styles.header_page_box}`}
              data-aos="zoom-in"
            >
              <h2>{t.about_aitham}</h2>
            </div>
            <div className={styles.about_page_holder}>
              {/* START COMPONENT */}
              <div
                className={styles.text_social_component_holder}
                data-aos="fade-left"
              >
                <div className={styles.text_social_component}>
                  <div className={styles.box_style}>
                    <div className={styles.bg_color}></div>
                    <h2 className={`solid-txt ${styles.solid_text}`}>
                      Asma Marine
                    </h2>
                  </div>

                  <Row gutter={[60, 50]} justify="center">
                    <Col xs={12} md={9} lg={8} xl={7}>
                      <div className={styles.img_holder_style}>
                        <Image
                          src={img_1}
                          style={{ width: "23rem" }}
                          preview={false}
                        />
                      </div>
                    </Col>
                    <Col xs={24} md={24} lg={10} xl={12}>
                      <div className={styles.text_content}>
                        {/* <h4>Place your title here</h4> */}
                        <div>
                          <p>{t.FOR_about_hitham_text_1}</p>
                          <p>{t.FOR_about_hitham_text_2}</p>
                          <p>{t.FOR_about_hitham_text_3}</p>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={9} lg={6} xl={5}>
                      <div className={styles.social_holder}>
                        <ul>
                          <li>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://www.facebook.com/HaithaminChina"
                            >
                              <FaFacebookF />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://www.youtube.com/c/haithaminchina/featured"
                            >
                              <FaYoutube />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://www.twitter.com/HaithaminChina"
                            >
                              <FaTwitter />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://www.instagram.com/haithaminchina"
                            >
                              <FaInstagram />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://www.linkedin.com/company/71660921"
                            >
                              <FaLinkedinIn />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://www.tiktok.com/@haithaminchina"
                            >
                              <FaTiktok />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              {/* END COMPONENT */}

              <div className={styles.title_with_image} data-aos="fade-right">
                <div className={styles.content_holder}>
                  <div className={styles.text_content}>
                    <div className={styles.bg_color}></div>
                    <div className={styles.img_holder}>
                      <Image
                        src={img_2}
                        style={{ width: "11rem" }}
                        preview={false}
                      />
                    </div>
                    <h2>{t.Haitham_certificates}</h2>
                  </div>
                  <div className={styles.img_holder_style}>
                    <Image src={img_3} preview={false} />
                  </div>
                </div>
              </div>

              {/* start */}

              <div className={styles.about_artical_hero}>
                <div
                  className={styles.artical_holder}
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  <Row gutter={[40, 20]} align="middle">
                    <Col xs={24} md={7}>
                      <div className={styles.logo_holder}>
                        <Image
                          src={logo_4}
                          style={{ width: "18rem" }}
                          preview={false}
                        />
                      </div>
                    </Col>
                    <Col xs={24} md={17}>
                      <div className={styles.text_holder}>
                        <h2>{t.FOR_about_title_1}</h2>
                        {/* <p>
                          Lorem ipsum dolor 
                        </p> */}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div
                  className={styles.artical_holder}
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  <Row gutter={[40, 20]} align="middle">
                    <Col xs={24} md={7}>
                      <div className={styles.logo_holder}>
                        <Image
                          src={logo_5}
                          style={{ width: "18rem" }}
                          preview={false}
                        />
                      </div>
                    </Col>
                    <Col xs={24} md={17}>
                      <div className={styles.text_holder}>
                        <h2>{t.FOR_about_title_2}</h2>
                        {/* <p>
                          Lorem ipsum dolor 
                        </p> */}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div
                  className={styles.artical_holder}
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  <Row gutter={[40, 20]} align="middle">
                    <Col xs={24} md={7}>
                      <div className={styles.logo_holder}>
                        <Image
                          src={logo_2}
                          style={{ width: "18rem" }}
                          preview={false}
                        />
                      </div>
                    </Col>
                    <Col xs={24} md={17}>
                      <div className={styles.text_holder}>
                        <h2>{t.FOR_about_title_3}</h2>
                        {/* <p>
                          Lorem ipsum dolor 
                        </p> */}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div
                  className={styles.artical_holder}
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  <Row gutter={[40, 20]} align="middle">
                    <Col xs={24} md={7}>
                      <div className={styles.logo_holder}>
                        <Image
                          src={logo_3}
                          style={{ width: "18rem" }}
                          preview={false}
                        />
                      </div>
                    </Col>
                    <Col xs={24} md={17}>
                      <div className={styles.text_holder}>
                        <h2>{t.FOR_about_title_4}</h2>
                        {/* <p>
                          Lorem ipsum dolor 
                        </p> */}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div
                  className={styles.artical_holder}
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  <Row gutter={[40, 20]} align="middle">
                    <Col xs={24} md={7}>
                      <div className={styles.logo_holder}>
                        <Image
                          src={logo_1}
                          style={{ width: "18rem" }}
                          preview={false}
                        />
                      </div>
                    </Col>
                    <Col xs={24} md={17}>
                      <div className={styles.text_holder}>
                        <h2>{t.FOR_about_title_5}</h2>
                        {/* <p>
                          Lorem ipsum dolor 
                        </p> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              <HaithamSocials
                t={t}
                title={t.Haitham_Socials}
                settings={homeData?.settings}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
